import {
  Box,
  FormControl,
  FormErrorMessage,
  useBoolean,
  VStack,
} from '@chakra-ui/react'
import { FormChoice } from 'form/components/choice'
import { FormConfirm } from 'form/components/confirm'
import { FieldValidator } from 'form/components/form'
import { QuestionTitle } from 'form/components/title'
import { FormAnswer } from 'form/entities/answer'
import { FormField } from 'form/entities/field'
import { useStateWithCallback } from 'infra/react'
import { FC, MutableRefObject, useEffect } from 'react'

interface FieldProps {
  validator: MutableRefObject<FieldValidator>
  answer: FormAnswer
  field: FormField
  position: number
  isLast: boolean
  onDone: (answer: FormAnswer) => void
}

const choices = [
  {
    id: 'yes',
    label: 'Sim',
  },
  {
    id: 'no',
    label: 'Não',
  },
]

export const FormYesNoField: FC<FieldProps> = props => {
  const { validator, answer, position, isLast, field, onDone } = props

  const [error, setError] = useBoolean()
  const [responded, setResponded] = useBoolean()
  const [fieldAnswer, setFieldAnswer] = useStateWithCallback<FormAnswer>({
    field: answer?.field || field._id,
    name: field.name || '',
    type: answer?.type || 'choice',
    value: answer?.value || '',
  })

  const isFieldValid = () => {
    if (!!!fieldAnswer.value && field.required) {
      setError.on()
      return false
    } else {
      setError.off()
      return true
    }
  }

  const onSubmit = () => {
    if (!isFieldValid) return
    onDone(fieldAnswer)
  }

  useEffect(() => {
    validator.current = isFieldValid
    if (fieldAnswer.value && responded) {
      setTimeout(onSubmit, 600)
    }
  }, [fieldAnswer])

  return (
    <VStack w={'full'} align={'flex-start'}>
      <QuestionTitle
        title={position + 1 + ' - ' + field.title}
        description={field.description}
      />
      <FormControl isInvalid={error}>
        <Box overflowY={'scroll'} overflowX={'hidden'}>
          <VStack align='flex-start' overflowY={'scroll'}>
            {choices &&
              choices.map(choice => {
                const isActive = fieldAnswer?.value === choice.label
                return (
                  <Box
                    id={`choice-${choice.id}`}
                    cursor='pointer'
                    key={choice.id}
                    py={1}
                    px={4}
                    fontSize='md'
                    minW='xs'
                    borderRadius={8}
                    border='2px solid'
                    fontWeight={isActive ? 700 : '400'}
                    borderColor={isActive ? 'primary' : 'white'}
                    bg={isActive ? 'primary' : 'gray.100'}
                    color={isActive ? 'white' : 'inherit'}
                    _hover={{
                      bg: isActive ? 'primary' : 'gray.300',
                      transition: '50ms',
                    }}
                    onClick={() => {
                      setResponded.on()
                      setFieldAnswer({
                        ...fieldAnswer,
                        value: choice.label,
                      })
                    }}
                  >
                    <FormChoice label={choice.label} isActive={isActive} />
                  </Box>
                )
              })}
          </VStack>
        </Box>
        {error && (
          <FormErrorMessage px={4} h={'51px'} fontSize={'md'} fontWeight={500}>
            Preencha este campo
          </FormErrorMessage>
        )}
      </FormControl>
      {!!!error && (
        <FormConfirm label={isLast ? 'Enviar' : 'OK'} />
      )}
    </VStack>
  )
}
