export const scheduleQuery = `
  query schedule($key: String!) {
    schedule(key: $key) {
      _id
      _key
      _rev
      account {
        _id
      }
      creator {
        _id
        _key
        name
        secondName
        avatar
      }
      calendar
      name
      active
      afterBufferTime
      beforeBufferTime
      cancellationAllowed
      description
      duration
      startDate
      endDate
      kind
      timeZone
      product {
        name
        description
        price
        discountPrice
        amount
        maxInstallments
      }
      fields {
        id
        name
        required
        format
        position
        enabled
      }
    }
  }
`

export const schedulesQuery = `
  query schedules($filter: ScheduleFilter!) {
    schedules(filter: $filter) {
      _id
      _key
      _rev
      account {
        _id
      }
      creator {
        name
        secondName
        avatar
      }
      calendar
      name
      color
      active
      afterBufferTime
      beforeBufferTime
      cancellationAllowed
      description
      duration
      startDate
      endDate
      kind
      timeZone
      product {
        _id
        _key
        _rev
        name
        description
        price
        amount
        maxInstallments
      }
      fields {
        id
        name
        required
        format
        position
        enabled
      }
    }
  }
`

export const scheduleAvailabilityQuery = `
  query availability($data: AvailabilityRequest!) {
    availability(data: $data) {
      today
        days {
          date
          status
          slots {
            start
            status
          }
      }
    }
  }
`

export const bookingsQuery = `
  query bookings($filter: BookingFilter!) {
    bookings(filter: $filter) {
      _id
      _key
      name
      start
      end
      timeZone
      refunded
      meetId
      meetUrl
      status
      invitee {
        _id
        _key
        name
        email
      }
    }
  }
`
