import { HttpProvider } from 'infra/http'
import { dt, locator } from 'infra/locator'
import {
  Testimonial,
  TestimonialFilter,
} from 'testimonial/entities/testimonial'
import { testimonialsGraphqlQuery } from 'testimonial/graphql/queries'

export class TestimonialProvider {
  private http: HttpProvider

  constructor() {
    this.http = locator.get<HttpProvider>(dt.Http)
  }

  testimonials = async (filter: TestimonialFilter) => {
    return this.http
      .graphql<{ testimonials: Testimonial[] }>(testimonialsGraphqlQuery, {
        filter,
      })
      .then(r => r.testimonials)
  }
}
