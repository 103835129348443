export const createBookingMutation = `
  mutation createBooking($data: BookingInput!) {
    createBooking(data: $data) {
      _id
      _key
      _rev
    }
  }
`

export const cancelBookingMutation = `
  mutation cancelBooking($id: String!) {
    cancelBooking(id: $id) {
      _id
      _key
      _rev
    }
  }
`

export const updateBookingMutation = `
  mutation updateBooking($data: BookingInput!) {
    updateBooking(data: $data) {
      _id
      _key
      _rev
      name
      start
      end
      timeZone
      invoiceStatus
      invoiceUrl
      canceled
      createdAt
      updatedAt
    }
  }
`

export const updateScheduleMutation = `
  mutation updateSchedule($data: ScheduleInput!) {
    updateSchedule(data: $data) {
      _id
      _key
      _rev
    }
  }
`

export const removeScheduleMutation = `
  mutation removeSchedule($key: String!) {
    removeSchedule(key: $key) {
      _id
      _key
      _rev
    }
  }
`
