import { Button, HStack, Input, Text, VStack } from '@chakra-ui/react'
import { FC } from 'react'
import { Controller, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { toast } from 'react-toastify'
import { numbersOnly } from 'utils/string'
import { validatePhone } from 'utils/validation'

interface Props {
  onConfirm: (phone: string) => void
}

export const PhoneForm: FC<Props> = props => {
  const { onConfirm } = props

  const { control, handleSubmit } = useForm<{
    phone: string
  }>()

  const onSubmit = handleSubmit((data: { phone: string }) => {
    if (!data.phone) {
      return toast.error('Digite o celular com DDD')
    }

    const sanitizedPhone = numbersOnly(data.phone)
    if (!validatePhone(sanitizedPhone)) {
      return toast.error('Por favor, preencha este campo')
    }

    onConfirm(`+55${sanitizedPhone}`)
  })

  const PhoneInput = (props: any) => {
    return (
      <Input
        autoComplete='tel'
        variant='outline'
        fontSize='2xl'
        placeholder='(41) 99999-9999'
        {...props}
        type={'tel'}
        borderRadius={'md'}
      />
    )
  }

  return (
    <VStack justify='center' align='center' spacing={8}>
      <VStack spacing={1}>
        <Text textStyle={'h1'} textAlign='center'>
          Acessar Berrytech
        </Text>
        <Text>digite seu telefone com DDD</Text>
      </VStack>
      <HStack>
        <Controller
          control={control}
          name='phone'
          render={({ field: { onChange, value } }) => {
            return (
              <NumberFormat
                autoFocus
                name='phone'
                value={value}
                placeholder='(41) 99999-9999'
                customInput={PhoneInput as any}
                displayType='input'
                format={'(##) #####-####'}
                onValueChange={value => onChange(value.formattedValue)}
              />
            )
          }}
        />
      </HStack>
      <Text textStyle='caption'>
        Digite seu celular para entrar. Seus dados estão seguros e você não
        precisa de senha.
      </Text>
      <Button w={'full'} onClick={onSubmit}>
        Receber código por SMS
      </Button>
    </VStack>
  )
}
