import { Button, chakra, HStack, Text } from '@chakra-ui/react'
import { FC } from 'react'
import { BsCheck } from 'react-icons/bs'

interface Props {
  label: string
}

export const FormConfirm: FC<Props> = props => {
  const {  label } = props
  return (
    <HStack spacing={6} overflow={'hidden'} py={3}>
      <Button
        
        rightIcon={label !== 'OK' ? undefined : <BsCheck size={20} />}
      ><a href="https://berry.tech">{label}</a>
      </Button>
      <Text fontSize='xs'>
        aperte <chakra.span fontWeight={700}>Enter ↵</chakra.span>{' '}
      </Text>
    </HStack>
  )
}
