import { Account } from 'account/entities/account'
import {
  accountMarketingParamsQuery,
  accountQuery,
} from 'account/graphql/queries'
import { HttpProvider } from 'infra/http'
import { dt, locator } from 'infra/locator'

export class AccountProvider {
  private http: HttpProvider

  constructor() {
    this.http = locator.get<HttpProvider>(dt.Http)
  }

  account = async (key: string) => {
    return this.http
      .graphql<{ account: Account }>(accountQuery, { key })
      .then(r => r.account)
  }

  marketingParams = async (id: string) => {
    return this.http
      .graphql<{ accountMarketingParams: Account }>(
        accountMarketingParamsQuery,
        { id },
      )
      .then(r => r.accountMarketingParams)
  }
}
