import { FormControl, FormErrorMessage, Input, VStack } from '@chakra-ui/react'
import { FormConfirm } from 'form/components/confirm'
import { FieldValidator } from 'form/components/form'
import { QuestionTitle } from 'form/components/title'
import { FormAnswer } from 'form/entities/answer'
import { FormField } from 'form/entities/field'
import { FC, MutableRefObject, useEffect, useRef } from 'react'
import { useForm, useFormState } from 'react-hook-form'

interface FieldProps {
  validator: MutableRefObject<FieldValidator>
  answer: FormAnswer
  field: FormField
  position: number
  isLast: boolean
  onDone: (answer: FormAnswer) => void
}

export const FormTextField: FC<FieldProps> = props => {
  const { validator, answer, position, isLast, field, onDone } = props

  const valueRef = useRef<any>(null)

  const { register, getValues, setError, control, handleSubmit } =
    useForm<FormAnswer>({
      defaultValues: {
        field: answer?.field || field._id,
        name: field.name || '',
        type: answer?.type || 'text',
        value: answer?.value || '',
      },
    })
  const { ref, ...rest } = register('value')
  const { errors } = useFormState({ control })

  const isFieldValid = () => {
    const data = getValues()

    if (!data.value && field.required) {
      setError('value', {
        message: 'Por favor, preencha este campo',
      })
      return false
    }

    return true
  }

  const onSubmit = handleSubmit((data: FormAnswer) => {
    if (!isFieldValid()) return
    onDone(data)
  })

  const onKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      onSubmit()
    }
  }

  useEffect(() => {
    validator.current = isFieldValid as any
    document.addEventListener('keydown', onKeyDown)

    if (valueRef.current) {
      valueRef.current.focus()
    }

    return () => document.removeEventListener('keydown', onKeyDown)
  }, [ref])

  return (
    <VStack w={'full'} align={'flex-start'}>
      <QuestionTitle
        title={position + 1 + ' - ' + field.title}
        description={field.description}
      />
      <FormControl isInvalid={!!errors.value?.message}>
        <Input
          autoComplete='off'
          variant='ghost'
          borderRadius={0}
          pl={0}
          pb={2}
          fontSize='xl'
          borderBottom='1px solid'
          placeholder={'Digite sua resposta aqui'}
          ref={e => {
            ref(e)
            valueRef.current = e
          }}
          {...rest}
        />
        {errors.value && (
          <FormErrorMessage pt={1} pb={4} fontWeight={600}>
            {errors.value.message}
          </FormErrorMessage>
        )}
      </FormControl>
      {!!!errors.value?.message && (
        <FormConfirm  label={isLast ? 'Enviar' : 'OK'} />
      )}
    </VStack>
  )
}
