export const Routes = {
  AboutUs: '/quem-somos',
  App: '/portal',
  BerrrymindBookings: '/portal/berrymind/agendamentos',
  Berrymind: '/portal/berrymind',
  BerrymindValidate: '/berrymind/validate',
  BerrymindBook: '/portal/berrymind/agendar',
  BerrymindCredits: '/portal/berrymind/creditos',
  BerrymindLP: '/berrymind',
  BerrymindOrders: '/portal/berrymind/pedidos',
  Consulting: '/portal/consultoria',
  Contact: '/contato',
  CourseLearn: '/portal/estudar/',
  Courses: '/portal/cursos',
  Enrollments: '/portal/inscritos',
  Franchise: '/franquia',
  Home: '/',
  Invoices: '/portal/cobrancas',
  Login: '/acessar',
  Marketing: '/portal/marketing',
  Profile: '/portal/perfil',
  Testimonials: '/depoimentos',
}
