export const testimonialsGraphqlQuery = `
  query testimonials($filter: TestimonialFilterInput!) {
    testimonials(filter: $filter) {
      _id
      _key
      _rev
      account {
        _id
        _key
        _rev
        name
        master {
          _id
          _key
          _rev
          name
          secondName
        }
      }
      company
      person
      avatar
      videoUrl
      sales
      profit
      testimony
    }
  }
`
