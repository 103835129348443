import {
  Box,
  BoxProps,
  Button,
  HStack,
  SlideFade,
  Text,
  useBoolean,
} from '@chakra-ui/react'
import React from 'react'
import { HiChevronLeft } from 'react-icons/hi'

interface Props extends BoxProps {
  label?: string
  onClick: VoidFunction
}

export const BackButton: React.FC<React.PropsWithChildren<Props>> = ({
  onClick,
  label,
  ...rest
}) => {
  const [hover, setHover] = useBoolean(false)

  return (
    <HStack
      cursor={'pointer'}
      minW={'120px'}
      justify='flex-start'
      onMouseEnter={setHover.on}
      onMouseLeave={setHover.off}
      onClick={() => onClick()}
      {...rest}
    >
      <Button
        leftIcon={
          <Box w={'32px'}>
            <HiChevronLeft size={28} />
          </Box>
        }
        variant={'link'}
        bg={'transparent'}
        py={2}
        color={'heading'}
      ></Button>
      <SlideFade in={hover}>
        <Text textStyle='h6'>{label || 'Voltar'}</Text>
      </SlideFade>
    </HStack>
  )
}
