export const coursesQuery = `
  query courses($filter: CourseFilter!) {
    courses(filter: $filter) {
      _id
      _key
      name
      description
      cover
      duration
      visibility
      published
      instructors {
        _id
        _key
        name
      }
      pricing {
        type
        price
        discountPrice
        interval
      }
      objectives
      requirements
    }
  }
`

export const courseQuery = `
  query course($key: String!) {
    course(key: $key) {
      _id
    _key
    name
    description
    cover
    duration
    visibility
    published
    instructors {
      _id
      _key
      name
    }
    curriculum {
      type
      id
      name
      source
      published
      order
    }
    lectures {
      _id
      _key
      name
      description
      published
      content {
        type
        name
        body
        sourceId
        sourceUrl
        duration
        thumbnail
        summary
        }
        extras {
          type
          name
          body
          sourceId
          sourceUrl
          duration
          thumbnail
          summary
        }
      }
      quizzes {
        _id
        _key
        type
        question
        answers {
          content
          feedback
          correct
        }
      }
      objectives
      requirements
    }
  }
`
