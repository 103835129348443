import {
  createPullstateCore,
  IPullstateAsyncActionOrdState,
  IPullstateAsyncResultState,
  Store as PullStateStore,
} from 'pullstate'
import { useMemo } from 'react'
import { AuthStore } from 'stores/auth'
import { BerrymindStore } from 'stores/berrymind'
import { FormStore, FormStoreInterface } from 'stores/form'
import { FranchiseStore } from 'stores/franchise'
import { LayoutStore } from 'stores/layout'

export interface StoreState {
  auth: AuthStore
  berrymind: BerrymindStore
  form: FormStoreInterface
  franchise: FranchiseStore
  layout: LayoutStore
}

export const Store = new PullStateStore<StoreState>({
  auth: AuthStore,
  berrymind: BerrymindStore,
  form: FormStore,
  franchise: FranchiseStore,
  layout: LayoutStore,
})

export const PullstateCore = createPullstateCore({
  Store,
})

export function useHydrate(snapshot: string) {
  return useMemo(() => {
    let instance
    if (!!!snapshot) {
      instance = PullstateCore.instantiate()
    } else {
      instance = PullstateCore.instantiate({
        hydrateSnapshot: JSON.parse(snapshot),
      })
    }
    return instance
  }, [snapshot])
}

export interface IPullstateSnapshot {
  allState: {
    [storeName: string]: any
  }
  asyncResults: IPullstateAsyncResultState
  asyncActionOrd: IPullstateAsyncActionOrdState
}

type StoreType = { Store: PullStateStore<StoreState> }
export interface PullstateInstance {
  instantiateReactions(): void
  getPullstateSnapshot(): IPullstateSnapshot
  resolveAsyncState(): Promise<void>
  hasAsyncStateToResolve(): boolean
  get stores(): StoreType
  get customContext(): any
  hydrateFromSnapshot(snapshot: IPullstateSnapshot): void
}
