import { FormResponse, SendGridContactRequest } from 'form/entities/answer'
import { Form } from 'form/entities/form'
import {
  addToMailingListMutation,
  respondToFormMutation,
  updateFormResponseMutation,
} from 'form/graphql/mutations'
import { formQuery } from 'form/graphql/queries'
import { HttpProvider } from 'infra/http'
import { Model } from 'infra/interfaces'
import { dt, locator } from 'infra/locator'

export class FormProvider {
  private http: HttpProvider

  constructor() {
    this.http = locator.get<HttpProvider>(dt.Http)
  }

  form = async (id: string) => {
    return this.http
      .graphql<{ form: Form }>(formQuery, { id })
      .then(res => res.form)
  }

  respondToForm = async (data: Partial<FormResponse>) => {
    return this.http
      .graphql<{ respondToForm: Model }>(respondToFormMutation, { data })
      .then(res => res.respondToForm)
  }

  updateResponse = async (data: Partial<FormResponse>) => {
    return this.http
      .graphql<{ updateFormResponse: Model }>(updateFormResponseMutation, {
        data,
      })
      .then(res => res.updateFormResponse)
  }

  addToMailingList = async (data: SendGridContactRequest) => {
    console.log('addToMailingList', data)
    return this.http
      .graphql<{ addToMailingList: Boolean }>(addToMailingListMutation, {
        data,
      })
      .then(res => res.addToMailingList)
  }
}
