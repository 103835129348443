import { Account } from 'account/entities/account'
import { FormResponse } from 'form/entities/answer'
import { Form } from 'form/entities/form'
import { global } from 'global'
import { GoogleAds, storeEnhancedConversionData } from 'infra/google'
import { dt, locator } from 'infra/locator'

interface FormParams {
  form: Form
  franchise: Account
  response: Partial<FormResponse>
}

export class ConvertFormUseCase {
  async execute(params: FormParams) {
    const { conversions } = params.form

    if (!!!conversions || conversions.length === 0) return
    if (!!!params.response.answers) return

    const gads = locator.get<GoogleAds>(dt.GoogleAds)
    const answers = params.response.answers

    let enhancedData: any = {}

    for (const answer of answers) {
      if (answer.name?.includes('email')) {
        enhancedData.email = answer.value
      }
      if (answer.name?.includes('phone')) {
        enhancedData.phone = answer.value
      }
      if (answer.name?.includes('name')) {
        enhancedData.name = answer.value
      }
    }

    storeEnhancedConversionData(enhancedData)

    for (const conversion of conversions) {
      const isFranchiseAccount = conversion.account === params.franchise._key
      if (isFranchiseAccount) {
        if (conversion.type === 'google') {
          gads.conversion(conversion.action)
        }
        if (conversion.type === 'facebook' && global.fbq) {
          global.fbq('trackCustom', conversion.action, {
            currency: 'BRL',
            value: 3,
          })
        }
      }
    }
  }
}
