export const enrollCourseMutation = `
  mutation enrollCourse($id: String!) {
    enrollCourse(id: $id) {
      _id
      _key
      _rev
    }
  }
`
export const updateEnrollmentMutation =`
  mutation updateEnrollment($data: EnrollmentInput!) {
    updateEnrollment(data: $data) {
      _id
      _key
      _rev
    }
  }
`