import { Text, VStack } from '@chakra-ui/react'
import { FC } from 'react'

interface Props {
  title: string
  description?: string
}

export const QuestionTitle: FC<Props> = props => {
  const { title, description } = props
  return (
    <VStack py={3} spacing={0} maxW='lg' align='flex-start'>
      <Text textStyle='h2'>{title}</Text>
      <Text textStyle='caption'>{description}</Text>
    </VStack>
  )
}
