import {
  Box,
  Button,
  HStack,
  SlideFade,
  Switch,
  Text,
  useBoolean,
  VStack
} from '@chakra-ui/react'
import { BackButton } from 'common/back'
import { getCookie, setCookie } from 'cookies-next'
import { appConstants } from 'global'
import { useEffect, useState } from 'react'

interface CookieOption {
  name: string
  code: string
  description: string
  active: boolean
  required: boolean
}

const cookieOptions: CookieOption[] = [
  {
    name: 'Necessários',
    code: 'required',
    description:
      'Os cookies necessários são essenciais para o funcionamento do site, sem eles o site não funcionaria corretamente. (por exemplo, acesso a áreas seguras do site, segurança, legislação)',
    active: true,
    required: true,
  },
  {
    name: 'Estatísticas',
    code: 'stats',
    description:
      'Os cookies de estatísticas, ou análises, traduzem as interações dos visitantes em relatórios detalhados de comportamento, de maneira anônima.',
    active: true,
    required: false,
  },
  {
    name: 'Funcionais',
    code: 'function',
    description:
      'Os cookies funcionais ajustam o site a serviços de terceiros, como links para seu perfil em redes sociais, comentários, chatbots, etc.',
    active: true,
    required: false,
  },
  {
    name: 'Performance',
    code: 'performance',
    description:
      'Os cookies de desempenho permitem que o site se comporte de acordo com o visitante, ajustando-se à sua localização, idioma preferido, etc.',
    active: true,
    required: false,
  },
  {
    name: 'Marketing',
    code: 'marketing',
    description:
      'Os cookies de marketing ou publicidade rastreiam a navegação dos visitantes e coletam dados para que a empresa possa fornecer anúncios mais relevantes de acordo com tal comportamento.',
    active: true,
    required: false,
  },
]

export const CookieConsent = () => {
  const [options, setOptions] = useState(cookieOptions)
  const [showOptions, setShowOptions] = useBoolean(false)
  const [hasConsent, setHasConsent] = useBoolean(false)

  const restoreConsent = () => {
    const stored = getCookie(appConstants.CookieConsent) as string
    if (!!stored) {
      const json = JSON.parse(Buffer.from(stored, 'base64').toString('utf8'))
      setOptions(json)
      setHasConsent.on()
    }
  }

  const saveConsent = () => {
    const toStore = Buffer.from(JSON.stringify(options)).toString('base64')
    setCookie(appConstants.CookieConsent, toStore)
  }

  const onAcceptCookies = () => {
    saveConsent()
    setHasConsent.on()
  }

  useEffect(() => {
    restoreConsent()
  }, [])

  return (
    <SlideFade in={!hasConsent}>
      <HStack
        bottom={[0, 4]}
        left={[0, 4]}
        m={'auto'}
        position={'fixed'}
        justify={'center'}
        zIndex={'modal'}
        borderRadius={'md'}
        boxShadow={'0px 0px 37px 1px rgba(0,0,0,0.28)'}
      >
        {showOptions ? (
          <VStack
            bg={'white'}
            w={['full', 'md']}
            px={6}
            boxShadow={'xl'}
            align={'flex-start'}
            border={'1px solid'}
            borderColor={'gray.200'}
            borderRadius={'md'}
            maxH={'250px'}
            overflowY={'auto'}
            spacing={4}
            py={4}
          >
            <BackButton onClick={setShowOptions.off} />
            {options.map((option, index) => (
              <HStack key={option.code} h={'full'} w={'full'} spacing={10}>
                <VStack w={'full'} align={'flex-start'}>
                  <Text textStyle={'h6'}>{option.name}</Text>
                  <Text textStyle={'caption'} fontSize={'xs'}>
                    {option.description}
                  </Text>
                </VStack>
                <Switch
                  isChecked={option.active}
                  onChange={() => {
                    if (option.required) {
                      return
                    }
                    const updated = [...options]
                    const opt = updated[index]
                    opt.active = !opt.active
                    updated[index] = opt
                    setOptions(updated)
                    saveConsent()
                  }}
                  colorScheme={'green'}
                />
              </HStack>
            ))}
          </VStack>
        ) : (
          <VStack
            bg={'white'}
            w={['full', 'md']}
            p={6}
            boxShadow={'xl'}
            align={'flex-start'}
            border={'1px solid'}
            borderColor={'gray.200'}
            borderRadius={'md'}
          >
            <Text textStyle={'h6'}>Controle sua privacidade</Text>
            <Text textStyle={'caption'} fontSize={'xs'}>
              Nosso site usa cookies para melhorar a sua experiência e navegação
            </Text>
            <Box h={2} />
            <HStack w={'full'} justify={'space-between'}>
              <Button
                variant={'icon'}
                fontSize={'xs'}
                onClick={setShowOptions.on}
              >
                Minhas Opções
              </Button>
              <Button onClick={onAcceptCookies} bg={'accent'}>
                Aceitar cookies
              </Button>
            </HStack>
          </VStack>
        )}
      </HStack>
    </SlideFade>
  )
}
