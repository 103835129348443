export const creditsOrdersQuery = `
  query credits {
    credits {
      _id
      _key
      _rev
      type
      product {
        _id
        name
      }
      amount
      total
      invoice {
        id
        status
      }
      invoiceStatus
      invoiceUrl
      status
      credited
      createdAt
      updatedAt
    }
  }
`
