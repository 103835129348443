export const berrymindSettingsQuery = `
  query berrymindSettings {
    berrymindSettings {
      _id
      _key
      psychologist {
        _id
        name
        avatar
      }
      user {
        _id
        _key
        _rev
        name
        secondName
      }
      credits
      refunds
    }
  }
`

export const berrymindPsychologistsQuery = `
  query berrymindPsychologists {
    berrymindPsychologists {
      _id
      _key
      avatar
      name
      secondName
      bio
    }
  }

`
