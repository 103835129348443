import { httpApiUrl } from 'infra/http'
import Script from 'next/script'
import { useState } from 'react'
import { User } from '../user/entities/user'

export const usePushNotifications = () => {
  const [hasPermission, setHasPermission] = useState(true)

  const initialize = async (user: User) => {
    if (!(process as any).browser) return
    const pusher = (window as any).PusherPushNotifications

    if (!!!pusher) return

    const url = `${httpApiUrl()}/push-token`
    const tokenProvider = new pusher.TokenProvider({ url })

    if (Notification.permission !== 'granted') {
      const result = await Notification.requestPermission()
      if (result === 'denied' || result === 'default') {
        setHasPermission(false)
      }
    }

    async function createBeamClient(serviceWorkerRegistration: any) {
      const client = new pusher.Client({
        instanceId: process.env.NEXT_PUBLIC_PUSHER_TOKEN,
        serviceWorkerRegistration,
      })

      await client.start()
      const userId = await client.getUserId()

      if (userId && userId !== user._id) {
        return client.stop()
      }

      if (!userId) {
        await client.setUserId(user._id, tokenProvider)
      }
    }

    window.navigator.serviceWorker.ready.then(createBeamClient)
  }

  return [hasPermission, initialize] as [boolean, (user: User) => void]
}

export const PushNotificationScript = () => {
  return (
    <Script
      id='pusher-script'
      strategy='afterInteractive'
      src={'https://js.pusher.com/beams/1.0/push-notifications-cdn.js'}
    />
  )
}
