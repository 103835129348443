import { Text, VStack } from '@chakra-ui/react'
import { FC } from 'react'

interface Props {
  title: string
  subtitle: string
  onClick: VoidFunction
}

export const DrawerItem: FC<Props> = props => {
  const { title, subtitle, onClick } = props
  return (
    <VStack
      onClick={onClick}
      cursor={'pointer'}
      spacing={0}
      align={'flex-start'}
      px={6}
      py={1}
      w={'full'}
    >
      <Text textStyle={'h5'}>{title}</Text>
      <Text textStyle={'caption'}>{subtitle}</Text>
    </VStack>
  )
}
