export const updateBerrymindSettingsMutation = `
  mutation updateBerrymindSettings($data: BerrymindSettingsInput!) {
    updateBerrymindSettings(data: $data) {
      _id
      _key
      _rev
    }
  }
`

export const updateBerrymindUserInfoMutation = `
  mutation updateBerrymindUserInfo($data: BerrymindUserInfo!) {
    updateBerrymindUserInfo(data: $data)
  }
`

export const transferCreditsMutation = `
  mutation transferCredits($data: TransferCreditsRequest!) {
    transferCredits(data: $data) {
      code
    }
  }
`

export const redeemCreditsMutation = `
  mutation redeemCredits($data: RedeemCreditsRequest!) {
    redeemCredits(data: $data) {
      redeemed
      amount
      notes
    }
  }
`
