import {
  sendPhoneValidationCodeMutation,
  validatePhoneCodeMutation,
} from 'auth/graphql/mutations'
import { httpApiUrl, HttpProvider } from 'infra/http'
import { dt, locator } from 'infra/locator'

export class AuthProvider {
  private http: HttpProvider

  constructor() {
    this.http = locator.get<HttpProvider>(dt.Http)
  }

  session = async (payload: Record<string, any>) => {
    const url = `${httpApiUrl()}/sessions`
    return this.http.post(url, payload).then(r => r.data)
  }

  sendPhoneValidationCode = async (phone: string) => {
    return this.http
      .graphql<{ sendPhoneValidationCode: boolean }>(
        sendPhoneValidationCodeMutation,
        {
          phone,
        },
      )
      .then(r => r.sendPhoneValidationCode)
  }

  validatePhoneCode = async (phone: string, code: string) => {
    return this.http
      .graphql<{ validatePhoneCode: boolean }>(validatePhoneCodeMutation, {
        data: {
          phone,
          code,
        },
      })
      .then(r => r.validatePhoneCode)
  }
}
