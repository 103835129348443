import { setCookie } from 'cookies-next'
import { appConstants } from 'global'
import { updateSentryContext } from 'infra/error'
import { HttpProvider } from 'infra/http'
import { dt, locator } from 'infra/locator'
import { Store } from 'stores/root'
import { UserProvider } from 'user/providers/user'

interface Request {
  token: string
}

export class AuthenticateSuccessUseCase {
  async execute(req: Request) {
    const httpProvider = locator.get<HttpProvider>(dt.Http)
    const userProvider = locator.get<UserProvider>(dt.User)

    httpProvider.setContext(req.token)
    setCookie(appConstants.CookieToken, req.token)

    const { user, account } = await userProvider.me()
    updateSentryContext(user)

    Store.update(s => {
      s.auth.user = user
      s.auth.token = req.token
      s.auth.account = account
    })

    await new Promise(resolve => setTimeout(resolve, 1000))
    
    return user
  }
}
