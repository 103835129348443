export const updateCourseMutation = `
  mutation updateCourse($data: CourseInput!) {
    updateCourse(data: $data) {
      _id
      _key
      _rev
    }
  }
`

export const archiveCourseMutation = `
  mutation archiveCourse($key: String!) {
    archiveCourse(key: $Key) {
      _id
      _key
      _rev
    }
  }
`
