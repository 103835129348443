import { getCookie, setCookie } from 'cookies-next'
import { appConstants } from 'global'
import { nanoid } from 'infra/nanoid'
import { useRouter } from 'next/router'
import { ParsedUrlQuery } from 'querystring'
import { useEffect, useState } from 'react'
import { UAParser } from 'ua-parser-js'

export const fetchQueryParams = (query: ParsedUrlQuery) => {
  const keys = Object.keys(query)

  const obj: any = {}

  for (const key of keys) {
    obj[key] = query[key]
  }

  return obj as Record<string, any>
}

export const fetchMarketingParams = () => {
  let mktObject: Partial<MarketingParams> = {}

  const storedParams = getCookie(appConstants.CookieMktInfo) as string
  if (storedParams) {
    const stringParams = Buffer.from(storedParams, 'base64').toString('utf8')
    const parsedParams = JSON.parse(stringParams || '{}') as Record<string, any>
    mktObject = Object.assign({}, mktObject, parsedParams)
  }

  Object.freeze(mktObject)

  return mktObject
}

export const saveParamsObject = (mktObject: Partial<MarketingParams>) => {
  if (!mktObject.anonymousId) {
    return
  }
  const base64Params = Buffer.from(JSON.stringify({ ...mktObject })).toString(
    'base64',
  )
  setCookie(appConstants.CookieMktInfo, base64Params)
}

export const getUserAgent = () => {
  var parser = new UAParser()
  var userOs = parser.getOS()
  var userBrowser = parser.getBrowser()
  const cpu = parser.getCPU()
  const engine = parser.getEngine()

  return {
    referer: document.referrer,
    cpu: cpu.architecture,
    browser: userBrowser.name + '/' + userBrowser.version,
    os: userOs.name + ' ' + userOs.version,
    engine: engine.name + ' ' + engine.version,
    resolution: window.screen.width + 'x' + window.screen.height,
  }
}

export const fetchIp = async () => {
  const data = await fetch('https://www.cloudflare.com/cdn-cgi/trace').then(r =>
    r.text(),
  )

  const dataObj = data
    .trim()
    .split('\n')
    .reduce(function (obj: any, pair: any) {
      pair = pair.split('=')
      return (obj[pair[0]] = pair[1]), obj
    }, {})

  return { ip: dataObj?.ip }
}

export const getFacebookCookieParams = () => {
  const fbpCookie = document.cookie
    .split(';')
    .filter(c => c.includes('_fbp='))
    .map(c => c.split('_fbp=')[1])
  const fbcCookie = document.cookie
    .split(';')
    .filter(c => c.includes('_fbc='))
    .map(c => c.split('_fbc=')[1])
  let fbp = (fbpCookie.length && fbpCookie[0]) || (null as any)
  let fbc = (fbcCookie.length && fbcCookie[0]) || (null as any)

  if (!fbc && window.location.search.includes('fbclid=')) {
    fbc =
      'fb.1.' + +new Date() + '.' + window.location.search.split('fbclid=')[1]
  }

  return { fbp, fbc }
}

export const keyMappings = {
  utm_id: 'utmId',
  utm_term: 'utmTerm',
  utm_source: 'utmSource',
  utm_medium: 'utmMedium',
  utm_campaign: 'utmCampaign',
  utm_content: 'utmContent',
  gclid: 'gclid',
  fbp: 'fbp',
  fbc: 'fbc',
  cpu: 'cpu',
  referer: 'referer',
  browser: 'browser',
  os: 'os',
  engine: 'engine',
  resolution: 'resolution',
  ip: 'ip',
  acc: 'account',
  anonymousId: 'anonymousId',
}

export const useSaveMarketingParams = () => {
  const { query } = useRouter()
  const [params, setParams] = useState<MarketingParams | null>(null)

  const saveMarketingParams = () => {
    let mktObject: Partial<MarketingParams> = {}

    const existingParams = fetchMarketingParams()
    mktObject = Object.assign({}, mktObject, existingParams)
    if (!existingParams.anonymousId) {
      mktObject.anonymousId = nanoid()
    }

    const qp = fetchQueryParams(query)
    mktObject = Object.assign({}, mktObject, qp)

    const fb = getFacebookCookieParams()
    mktObject = Object.assign({}, mktObject, fb)

    const ua = getUserAgent()
    mktObject = Object.assign({}, mktObject, ua)

    const ip = fetchIp()
    mktObject = Object.assign({}, mktObject, ip)

    const mapped = Object.assign({})
    for (let index = 0; index < Object.keys(mktObject).length; index++) {
      const key = Object.keys(mktObject)[index]
      const originalValue = Object.values(mktObject)[index]
      const idx = Object.keys(keyMappings).findIndex(s => key === s)
      const value = Object.values(keyMappings)[idx]
      if (value) {
        mapped[value] = originalValue
      }
    }

    Object.freeze(mapped)

    saveParamsObject(mapped)
    setParams(mapped)
  }

  useEffect(() => {
    if (query) {
      saveMarketingParams()
    }
  }, [query])

  return params
}

export interface MarketingParams {
  utmId: string
  utmTerm: string
  utmSource: string
  utmMedium: string
  utmCampaign: string
  utmContent: string
  gclid: string
  fbc: string
  fbp: string
  cpu: string
  referer: string
  browser: string
  os: string
  engine: string
  resolution: string
  ip: string
  path: string
  account: string
  anonymousId: string
}
