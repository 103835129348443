import { BerrymindSettings } from 'berrymind/entities/berrymind'
import { Draft } from 'immer'
import { StoreState } from 'stores/root'

export interface BerrymindStore {
  settings: BerrymindSettings
  book: {
    isOpen: boolean
    onDone: VoidFunction
  }
  redeem: {
    isOpen: boolean
  }
  transfer: {
    isOpen: boolean
  }
  credits: {
    isOpen: boolean
    onDone: VoidFunction
  }
}

export const BerrymindStore: BerrymindStore = {
  settings: {} as BerrymindSettings,
  book: {
    isOpen: false,
    onDone: () => {},
  },
  redeem: {
    isOpen: false,
  },
  transfer: {
    isOpen: false,
  },
  credits: {
    isOpen: false,
    onDone: () => {},
  },
}

export const updateBerrymindSettings =
  (settings: BerrymindSettings) => (s: Draft<StoreState>) => {
    s.berrymind.settings = settings
  }

export const closeBerrymindCreditsMutation = (s: Draft<StoreState>) => {
  s.berrymind.credits.onDone = () => {}
  s.berrymind.credits.isOpen = false
}

export const closeBerrymindBookingMutation = (s: Draft<StoreState>) => {
  s.berrymind.book.onDone = () => {}
  s.berrymind.book.isOpen = false
}
