import * as Sentry from '@sentry/browser'
import { User } from 'user/entities/user'

Sentry.init({
  dsn: 'https://4a7da161f304427a988ad4cf3214a4c5@sentry.berry.tech/2',
  environment:
    process.env.NODE_ENV === 'development' ? 'client_dev' : 'client_prod',
  integrations(integrations) {
    return integrations.filter(i => i.name !== 'TryCatch')
  },
})

export const captureMessage = (
  message: string,
  extras?: Record<string, any>,
) => {
  return Sentry.captureMessage(message, extras)
}

export let captureError = function (e: any, extras?: Record<string, any>) {
  if (process.env.NODE_ENV === 'development') {
    console.log(e)
  }
  if (e! instanceof Error && typeof e === 'string') {
    return Sentry.captureMessage(e)
  }
  return Sentry.captureException(e, {
    extra: extras,
  })
}

export const updateSentryContext = (user: User) => {
  captureError = function (e: any, extras?: any) {
    if (e! instanceof Error && typeof e === 'string') {
      return Sentry.captureMessage(e)
    }
    return Sentry.captureException(e, {
      extra: extras,
      user: {
        email: user.email,
        id: user._id,
        username: user.name,
      },
    })
  }
}
