export const enrollmentQuery = `
  query enrollment($key: String!) {
    enrollment(key: $key) {
      _id
      _key
      _rev
      type
      status
      course {
        _id
        _key
        name
        description
        cover
        duration
        visibility
        published
        instructors {
          _id
          _key
          name
        }
        curriculum {
          type
          id
          name
          source
          published
          order
        }
        lectures {
          _id
          _key
          name
          description
          published
          content {
            type
            module
            name
            body
            sourceId
            sourceUrl
            duration
            thumbnail
            summary
          }
          extras {
            type
            module
            name
            body
            sourceId
            sourceUrl
            duration
            thumbnail
            summary
          }
        }
        quizzes {
          _id
          _key
          type
          question
          answers {
            content
            feedback
            correct
          }
        }
        objectives
        requirements
      }
      student {
        _id
        _key
        name
      }
      duration
      progress
      start
      completed
      lastPlayed
      lastPlayedAt
    }
  }
`

export const enrollmentsQuery = `
  query enrollments($filter: EnrollmentFilter!) {
    enrollments(filter: $filter) {
      _id
      _key
      _rev
      type
      status
      course {
        _id
        _key
        name
        description
        cover
        duration
        visibility
        published
        instructors {
          _id
          _key
          name
        }
        pricing {
          type
          price
          discountPrice
          interval
        }
        objectives
        requirements
      }
      student {
        _id
        _key
        name
      }
      duration
      progress
      start
      lastPlayed
    }
  } 
`
