import { ChakraProvider } from '@chakra-ui/react'
import { FormModal } from 'form/components/form_modal'
import { CookieConsent } from 'infra/consent'
import { registerServices } from 'infra/locator'
import { PushNotificationScript, usePushNotifications } from 'infra/pusher'
import { AppDrawer } from 'layout/drawer'
import { LoginModal } from 'login/modal'
import { AppProps } from 'next/app'
import Head from 'next/head'
import NextNProgress from 'nextjs-progressbar'
import { PullstateProvider } from 'pullstate'
import { useEffect } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { getUserSelector } from 'stores/auth'
import { useHydrate } from 'stores/root'
import { chakraTheme } from 'theme'

registerServices()

// const WebVitals = dynamic((): any => import('infra/vitals'), {
//   ssr: false,
// })

function Berrytech({ Component, pageProps }: AppProps) {
  const [, initialize] = usePushNotifications()

  // const params = useSaveMarketingParams()
  const instance = useHydrate(pageProps.snapshot)
  const user = instance.stores.Store.useState(getUserSelector)

  useEffect(() => {
    if (user) {
      initialize(user)
    }
  }, [])

  // useEffect(() => {
  //   if (!!params) {
  //     locator.get<GoogleAds>(dt.GoogleAds).trackPageview()
  //   }
  // }, [[params]])

  return (
    <PullstateProvider instance={instance}>
      <ChakraProvider theme={chakraTheme}>
        <Head>
          <title>Berrytech - Nós decolamos empresas</title>
        </Head>
        <NextNProgress options={{ showSpinner: false }} height={4} />
        <ToastContainer
          position='top-right'
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme='colored'
        />
        <AppDrawer />
        <FormModal />
        <LoginModal />
        <CookieConsent />
        {/* <WebVitals /> */}
        <PushNotificationScript />
        <Component {...pageProps} />
      </ChakraProvider>
    </PullstateProvider>
  )
}

export default Berrytech
