import { captureException } from '@sentry/browser'
import axios, { AxiosInstance, AxiosResponse } from 'axios'
import { deleteCookie, getCookies } from 'cookies-next'
import { appConstants } from 'global'
import { logoutAction } from 'stores/auth'
import { Store } from 'stores/root'

export interface GraphQLResponse<T> {
  errors: string[]
  data: T
}

export const httpApiUrl = () => {
  let protocol = ''
  if (process.env.NODE_ENV === 'development') {
    protocol = `http://`
  } else {
    protocol = `https://`
  }
  return `${protocol + process.env.NEXT_PUBLIC_API_URL}`
}

export class HttpProvider {
  api: AxiosInstance
  token: string = ''
  protocol: string = ''

  constructor() {
    if (process.env.NODE_ENV === 'development') {
      this.protocol = `http://`
    } else {
      this.protocol = `https://`
    }
    this.api = axios.create({
      baseURL: `${httpApiUrl()}`,
    })

    const cookies = getCookies()
    if (cookies[appConstants.CookieToken]) {
      this.setContext(cookies[appConstants.CookieToken] as string)
    }
  }

  get = async (url: string) => {
    return this.api.get(url)
  }

  post = async (url: string, data?: any): Promise<AxiosResponse> => {
    return this.api.post(url, data)
  }

  postNoAuth = async (endpoint: string, payload: any) => {
    return fetch(`${process.env.NEXT_PUBLIC_API_URL}/${endpoint}`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    }).then(r => r.json())
  }

  clearContext() {
    this.token = ''
    this.api.defaults.headers.common['Authorization'] = ''
  }

  setContext(token: string) {
    this.token = token
    this.api.defaults.headers.common['Authorization'] = token as string
  }

  isAuthError = (status: number, message: string) => {
    return (
      [403, 401].includes(status) ||
      ['invalid_token', 'expired_token'].includes(message)
    )
  }

  graphql = async <T>(
    request: string,
    payload?: Record<string, any>,
  ): Promise<T> => {
    const url = `${httpApiUrl()}/q`
    try {
      const response = await this.api.post<GraphQLResponse<T>>(url, {
        query: request,
        variables: payload,
      })
      return response.data.data as T
    } catch (error: any) {
      if ([403, 401].includes(error.response.status)) {
        if (typeof window !== 'undefined') {
          deleteCookie(appConstants.CookieToken)
          deleteCookie(appConstants.CookieUserId)
        }
        Store.update(logoutAction)
        delete this.api.defaults.headers.common['Authorization']

        throw new Error('invalid_authentication_token')
      }

      console.log('response', error.response)

      const errors = error.response?.data?.errors

      if (typeof errors === 'string') {
        if (errors === 'expired_token') {
          deleteCookie(appConstants.CookieToken)
          deleteCookie(appConstants.CookieUserId)
          Store.update(logoutAction)
          delete this.api.defaults.headers.common['Authorization']
        }
      }

      const first = errors && errors.lenght > 0 ? errors[0] : []

      const err = new Error((first as any)?.error || 'Erro desconhecido')
      captureException(error)

      throw err
    }
  }
}
