export const formQuery = `
  query form($id: String!) {
    form(id: $id) {
      _id
      _key
      _rev
      title
      conversions {
        type
        account
        name
        action
      }
      success {
        title
        subtitle
      }
      fields {
        _id
        _key
        _rev
        form
        name
        title
        type
        order
        choices {
          id
          label
        }
        description
        allowMultiple
        allowOther
        vertical
        required
      }
    }
  }
 `
