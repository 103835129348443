import { extendTheme } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

const headingFont = `Poppins, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif;`
const bodyFont = `'Red Hat Display', system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif;`

const colors = {
  background: '#ffffff',
  backgroundAlt: '#f9f9f9',
  backgroundLight: '#E8F0FC',
  backgroundDark: '#0A2540',
  bodyText: 'rgb(11, 53, 88)',
  heading: '#353647',
  primary: '#005FFF',
  primaryDark: '#000E68',
  primaryLight: '#5487FF',
  danger: '#FFDFE1',
  warning: '#DB1436',
  success: '#4BB543',
  info: '#FED7A9',
  accent: '#26D49B',
  accentDark: '#20a377',
  accentAlt: '#4bedb7',
  filled: '#F6F6F6',
  hover: '#eaeaea',
}

const fonts = {
  heading: headingFont,
  body: bodyFont,
}

const layerStyles = {
  paper: {
    borderColor: 'gray.100',
    borderRadius: 4,
    padding: 6,
    '.chakra-ui-dark &': {
      bg: 'bgDarkAlt',
      shadow: 'none',
    },
  },
  filled: {
    bg: 'filled',
    borderRadius: 'md',
    padding: 4,
  },
  card: {
    border: '1px',
    borderColor: 'gray.100',
    borderRadius: 4,
    shadow: 'dividerShadow',
    padding: 4,
    '.chakra-ui-dark &': {
      bg: 'bgDarkAlt',
      shadow: 'none',
    },
  },
  normal: {
    bg: 'bgAlt',
    '.chakra-ui-dark &': {
      bg: 'bgAltDark',
    },
  },
  menuItem: {
    color: '#3A4A66',
    '.chakra-ui-dark &': {
      color: '#EEF3F7',
    },
  },
}

export const textStyles = {
  title: {
    fontFamily: headingFont,
    lineHeight: 1.075,
    color: 'heading',
    fontWeight: 700,
    fontSize: ['3rem', '4rem'],
    letterSpacing: '-.0125em',
    '.chakra-ui-dark &': {
      color: 'whiteAlpha.900',
    },
  },
  subtitle: {
    fontFamily: headingFont,
    lineHeight: 1,
    color: 'heading',
    fontWeight: 600,
    fontSize: ['2.5rem', '3.5rem'],
    letterSpacing: '-.0125em',
    '.chakra-ui-dark &': {
      color: 'whiteAlpha.900',
    },
  },
  h1: {
    fontFamily: headingFont,
    lineHeight: 1.2,
    fontWeight: 600,
    color: 'heading',
    fontSize: ['1.6rem', '2rem'],
    letterSpacing: '-.0125em',
    '.chakra-ui-dark &': {
      color: 'whiteAlpha.900',
    },
  },
  h2: {
    fontFamily: headingFont,
    lineHeight: 1.3,
    color: 'heading',
    fontWeight: 400,
    fontSize: ['1.25rem', '1.6rem'],
    '.chakra-ui-dark &': {
      color: 'white',
    },
    letterSpacing: '-.0125em',
    '& + h3': {
      mt: '1.5rem',
    },
  },
  h3: {
    fontFamily: headingFont,
    lineHeight: 1.25,
    color: 'heading',
    fontWeight: 600,
    fontSize: '1.25rem',
    letterSpacing: '-.0125em',
    '.chakra-ui-dark &': {
      color: 'white',
    },
  },
  h4: {
    fontFamily: headingFont,
    color: 'heading',
    lineHeight: 1.375,
    fontWeight: 700,
    fontSize: '1.125rem',
    '.chakra-ui-dark &': {
      color: 'whiteAlpha.900',
    },
  },
  h5: {
    fontFamily: headingFont,
    lineHeight: 1.25,
    color: 'heading',
    fontWeight: 700,
    fontSize: '1rem',
    '.chakra-ui-dark &': {
      color: 'whiteAlpha.900',
    },
  },
  h6: {
    fontFamily: headingFont,
    lineHeight: 1.25,
    fontWeight: 500,
    fontSize: '0.875rem',
    '.chakra-ui-dark &': {
      color: 'whiteAlpha.900',
    },
  },
  button: {
    fontSize: 'sm',
    fontWeight: 500,
    fontFamily: headingFont,
    letterSpacing: 'wide',
    color: 'heading',
    '.chakra-ui-dark &': {
      color: 'whiteAlpha.800',
    },
  },
  bodyAlt: {
    fontSize: '1.1rem',
    fontFamily: bodyFont,
    fontWeight: 500,
    color: 'gray.500',
    '.chakra-ui-dark &': {
      color: 'whiteAlpha.900',
    },
  },
  caption: {
    fontFamily: bodyFont,
    fontWeight: 400,
    fontSize: '1rem',
    color: 'gray.500',
    '.chakra-ui-dark &': {
      color: 'gray.400',
    },
  },
  linkPrimary: {
    fontFamily: bodyFont,
    fontWeight: 500,
    fontSize: 'md',

    color: 'primary',
    '.chakra-ui-dark &': {
      color: 'primary',
    },
  },
  overline: {
    fontFamily: bodyFont,
    fontWeight: 600,
    fontSize: 'md',
    color: 'primary',
    textTransform: 'uppercase',
    '.chakra-ui-dark &': {
      color: 'primary',
    },
  },
}

const Table = {
  variants: {
    striped: {
      tbody: {
        tr: {
          '&:nth-of-type(odd)': {
            'th, td': {
              borderBottomWidth: '1px',
              borderColor: '#F2F3F5',
            },
            td: {
              background: '#F2F3F5',
            },
            '.chakra-ui-dark &': {
              'th, td': {
                borderBottomWidth: '1px',
                borderColor: 'filledDark',
              },
              td: {
                background: 'filledDark',
              },
            },
          },
        },
      },
    },
  },
}

const shadows = {
  elevated: '0px 6px 50px rgba(0, 0, 0, 0.1);',
  elevetedBlue: '5px 10px 20px rgba(135, 190, 255, 0.6);',
  elevetedGreen: '5px 10px 20px rgba(38, 212, 155, 0.6);',
  dividerShadow:
    '0 1px 3px rgb(0 0 0 / 10%), 0 2px 2px rgb(0 0 0 / 6%), 0 0 2px rgb(0 0 0 / 7%)',
}

const Button = {
  baseStyle: {
    fontFamily: headingFont,
    zIndex: 'base',
    fontWeight: 200,
    borderRadius: 'md',
    _disabled: {
      color: 'black',
    },
  },
  variants: {
    solid: {
      bg: '#000000',
      color: 'white',
      _hover: {
        bg: '#333',
      },
    },
    outline: {
      fontSize: 'sm',
      fontWeight: 700,
      letterSpacing: 'wide',
      _hover: {
        bg: 'highlight',
        color: 'black',
      },
      '.chakra-ui-dark &': {
        _hover: {
          color: 'white',
          bg: 'highlightDark',
        },
      },
    },
    link: {
      fontFamily: bodyFont,
      fontSize: 'sm',
      fontWeight: 400,
      bg: 'none',
      '.chakra-ui-dark &': {
        bg: 'none',
        _hover: {
          color: 'primary',
        },
      },
      _hover: {
        color: 'primary',
        opactity: '0.6',
      },
    },
    accent: {
      fontSize: 'sm',
      fontWeight: 700,
      letterSpacing: 'wide',
      color: 'white',
      bg: 'accent',
      _hover: {
        bg: 'accentDark',
      },
      '.chakra-ui-dark &': {
        bg: 'green',
        color: 'highlight',
        _hover: {
          bg: 'green',
        },
      },
    },
    warning: {
      fontSize: 'sm',
      fontWeight: 700,
      letterSpacing: 'wide',
      color: 'white',
      bg: 'warning',
    },
    ghost: {
      fontSize: 'sm',
      fontWeight: 700,
      letterSpacing: 'wide',
      bg: 'filled',
      _hover: {
        bg: 'hover',
        '.chakra-ui-dark &': {
          _hover: {
            bg: 'filledDark',
          },
        },
      },
    },
    primary: {
      fontFamily: 'Helvetica',
      bg: 'primary',
      color: 'white',
      _focusWithin: {
        color: 'white',
        bg: 'primary',
        boxShadow: 'none',
      },
      _hover: {
        bg: 'primaryDark',
        boxShadow: 'lg',
        transition: '0.3s',
        _disabled: {
          bg: 'gray.200',
          boxShadow: 'none',
        },
      },
      '.chakra-ui-dark &': {
        bg: 'primary',
        _hover: {
          bg: 'primary',
          color: 'white',
          boxShadow: 'none',
          transition: '0.3s',
        },
      },
    },
    icon: {
      borderRadius: 4,
      bg: 'transparent',
      color: 'text',
      '.chakra-ui-dark &': {
        _hover: {
          color: 'primary',
          bg: 'filledDark',
        },
      },
      _hover: {
        color: 'primary',
        bg: 'filled',
      },
    },
    menuItem: {
      fontSize: 'sm',
      fontWeight: 500,
      color: 'text',
      '.chakra-ui-dark &': {
        _hover: {
          color: 'primary',
          bg: 'filledDark',
        },
      },
      _hover: {
        bg: 'filled',
        color: 'primary',
      },
    },
  },
}

const Input = {
  variants: {
    outline: {
      field: {
        border: '1px',
        borderColor: 'gray.100',
        bg: 'filled',
        fontWeight: 400,
        fontSize: '0.875rem',
        _hover: {
          borderColor: 'gray.300',
        },
        _focus: {
          borderColor: 'gray.100',
        },
      },
    },
  },
}

const Select = {
  variants: {
    filled: {
      field: {
        border: '1px',
        borderColor: 'gray.100',
        bg: 'filled',
        _hover: {
          borderColor: 'gray.300',
          bg: 'filled',
        },
        _focus: {
          borderColor: 'gray.100',
        },
      },
    },
  },
}

const Textarea = {
  variants: {
    filled: {
      field: {
        border: '1px',
        borderColor: 'gray.100',
        bg: 'filled',
        _focus: {
          boxShadow: 'none',
          bg: 'filled',
        },
      },
    },
  },
}

const Popover = {
  variants: {
    xs: {
      content: {
        width: '96px',
      },
      popper: {
        width: '96px',
      },
    },
    sm: {
      content: {
        width: '256px',
      },
      popper: {
        width: '256px',
      },
    },
    lg: {
      content: {
        width: '400px',
      },
      popper: {
        width: '400px',
      },
    },
    responsive: {
      content: {
        width: 'fit-content',
      },
      popper: {
        width: 'fit-content',
      },
    },
  },
}

export const Menu = {
  baseStyle: {
    item: {
      fontFamily: headingFont,
      _hover: {
        bg: 'highlight',
      },
    },
  },
}

const styles = {
  global: (theme: any) => ({
    '::-webkit-scrollbar-thumb': {
      borderRadius: '4px',
      height: '5px',
      background: mode('gray.400', '#333A49')(theme),
    },
    '::-webkit-scrollbar': {
      width: '6px',
      height: '5px',
    },
    '::-webkit-scrollbar-track': {
      width: '4px',
      height: '5px',
      background: 'background',
    },
    html: {
      fontFamily: bodyFont,
    },
    body: {
      fontSize: '18px',
      fontFamily: bodyFont,
      textAlign: 'left',
      fontWeight: 300,
      background: 'background',
      color: 'bodyText',
    },
  }),
}

export const Heading = {
  baseStyle: {
    color: 'heading',
  },
}

export const chakraTheme = extendTheme({
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
  shadows,
  colors,
  fonts,
  components: {
    Button,
    Table,
    Input,
    Popover,
    Textarea,
    Select,
    Menu,
    Heading,
  },
  layerStyles,
  textStyles,
  styles,
})
