import { TidioChat } from 'infra/chat'

interface Global {
  Vimeo: any
  gtag: any
  fbq: any
  tidioChatApi: TidioChat
}

export let global: Global = {} as Global
if (typeof window !== 'undefined') {
  global = window as any
}

export const appConstants = {
  CookieToken: 'bt_token',
  CookieUserId: 'bt_uid',
  CookieMktInfo: 'bt_reqc',
  CookieConsent: 'bt_cc',
  CookiePhoneVerificationSentAt: 'bt_pvsa',
}
