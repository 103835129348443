import {
  Button,
  HStack,
  PinInput,
  PinInputField,
  Text,
  VStack,
} from '@chakra-ui/react'
import { FC, useEffect, useRef } from 'react'
import { maskPhone } from 'utils/string'

interface Props {
  busy?: boolean
  phone: string
  onComplete: (code: string) => void
}

export const PhoneAuthentication: FC<Props> = props => {
  const { onComplete, busy, phone } = props

  const pinRef = useRef<any>()

  useEffect(() => {
    !!pinRef.current && pinRef.current?.focus()
  }, [pinRef])

  return (
    <VStack justify='center' align='center' spacing={8}>
      <VStack spacing={4}>
        <Text textStyle={'h5'} textAlign='center'>
          Código de acesso
        </Text>
        <Text textAlign='center'>
          Insira o código de 6 dígitos que enviamos para o número:
        </Text>
        <Text textStyle={'h3'}>{maskPhone(phone)}</Text>
      </VStack>
      <HStack>
        <PinInput autoFocus onComplete={onComplete} size='lg' otp>
          <PinInputField disabled={busy} fontSize={'xx-large'} />
          <PinInputField disabled={busy} fontSize={'xx-large'} />
          <PinInputField disabled={busy} fontSize={'xx-large'} />
          <PinInputField disabled={busy} fontSize={'xx-large'} />
          <PinInputField disabled={busy} fontSize={'xx-large'} />
          <PinInputField disabled={busy} fontSize={'xx-large'} />
        </PinInput>
      </HStack>
      <Button isLoading={busy} w={'full'}>
        Confirmar
      </Button>
    </VStack>
  )
}
