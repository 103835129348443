import { Account } from 'account/entities/account'
import { StoreState } from 'stores/root'

export interface FranchiseStore {
  account: Account
}

export const FranchiseStore = {
  account: {} as any,
}

export const getFranchiseAccount = (s: StoreState) => s.franchise.account
