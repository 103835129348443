import { deleteCookie } from 'cookies-next'
import { appConstants } from 'global'
import { HttpProvider } from 'infra/http'
import { dt, locator } from 'infra/locator'
import { NextRouter } from 'next/router'
import { Routes } from 'routes'
import { Store } from 'stores/root'

export class LogoutUseCase {
  async execute(router: NextRouter) {
    deleteCookie(appConstants.CookieToken)

    const http = locator.get<HttpProvider>(dt.Http)
    http.clearContext()

    Store.update(s => {
      s.auth.user = undefined
      s.auth.token = undefined
      s.auth.account = undefined
    })

    router.replace(Routes.Home)
  }
}
