export const sendPhoneValidationCodeMutation = `
  mutation sendPhoneValidationCode($phone: String!) {
    sendPhoneValidationCode(phone: $phone)
  }
`

export const validatePhoneCodeMutation = `
  mutation validatePhoneCode($data: ValidateTokenInput!) {
    validatePhoneCode(data: $data)
  }
`
