import { FC } from 'react'
import { PullstateCore } from 'stores/root'

export const IsAuthenticated: FC<any> = ({ children }) => {
  const { Store } = PullstateCore.useStores()
  const { user, token } = Store.useState(s => s.auth)

  if (!!!user || !!!token) {
    return null
  }

  return children
}

export const IsNotAuthenticated: FC<any> = ({ children }) => {
  const { Store } = PullstateCore.useStores()
  const { user, token } = Store.useState(s => s.auth)

  if (!!user || !!token) {
    return null
  }

  return children
}
