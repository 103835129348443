export const accountsQuery = `
    query accounts($filter: AccountFilterInput!) {
        accounts(filter: $filter) {
            _id
            _key
            _rev
            active
            name
            type
            project {
              _id
              _key
              _rev
            }
            person {
              name
              email {
                value
              }
              phone {
                value
              }
            }
            company {
                name
            }
            digitalAccount {
              accountId
            }
        }
    }
`

export const accountQuery = `
  query account($key: String!) {
    account(key: $key) {
      _id
      _key
      _rev
      name
      type
      active
      person {
        _id
        _key
        name
        taxId
      }
      company {
        _id
        _key
        name
        companyName
        taxId
        address {
          zip
          street
          number
          neighborhood
          city
          state
          country
          cityCode
        }
      }
      franchise {
        chargeRoyalties
        emitBillOfSale
      }
      digitalAccount {
        accountId
      }
      taxInfo {
        taxRegime
        nationalSimple
        cityCode
        cnae
        cityRegistrationId
        serviceCode
        cityTaxCode
        issAliquot
        issRetention
      }
      google {
        adAccount
        adAccountEmail
        trackingId
        analyticsId
        conversions {
          event
          action
        }
      }
      facebook {
        adAccount
        trackingId
        conversionApiAccessToken
        conversions {
          event
          action
        }
      }
    }
  }
`

export const accountMarketingParamsQuery = `
  query accountMarketingParams($id: String!) {
    accountMarketingParams(id: $id) {
      _id
      _key
      google {
        adAccount
        trackingId
        conversions {
          event
          action
        }
      }
      facebook {
        adAccount
        trackingId
        conversionApiAccessToken
        conversions {
          event
          action
        }
      }
    }
  }
`
