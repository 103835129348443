import { HttpProvider } from 'infra/http'
import { dt, locator } from 'infra/locator'
import { Model } from 'infra/model'
import {
  archiveLectureMutation,
  updateLectureMutation,
} from 'lecture/graphql/mutations'

export class LectureProvider {
  private http: HttpProvider

  constructor() {
    this.http = locator.get<HttpProvider>(dt.Http)
  }

  async update() {
    return this.http
      .graphql<{ updateLecture: Model }>(updateLectureMutation)
      .then(r => r.updateLecture)
  }

  async archive() {
    return this.http
      .graphql<{ archiveLecture: Model }>(archiveLectureMutation)
      .then(r => r.archiveLecture)
  }
}
