import React from 'react'

export type CallbackFunction = <T>(newValue: T, callback?: VoidFunction) => void

export function useStateWithCallback<T>(initialValue: T) {
  const callbackRef = React.useRef<any>(null)

  const [value, setValue] = React.useState<T>(initialValue)

  React.useEffect(() => {
    if (callbackRef.current) {
      callbackRef.current(value)
      callbackRef.current = null
    }
  }, [value])

  const setValueWithCallback = React.useCallback(
    (newValue: T, callback?: VoidFunction) => {
      callbackRef.current = callback
      return setValue(newValue)
    },
    [],
  )

  return [value, setValueWithCallback] as [T, CallbackFunction]
}
