import { Flex } from '@chakra-ui/react'
import { IsNotAuthenticated } from 'auth/components/auth'
import Link from 'next/link'

import { useRouter } from 'next/router'
import { FC } from 'react'
import { Routes } from 'routes'

export const navigationItems: NavigationButtonProps[] = [
  { destination: Routes.Home, label: 'Home' },
  { destination: Routes.AboutUs, label: 'Quem somos' },
  { destination: Routes.BerrymindLP, label: 'Berrymind' },
  { destination: Routes.Contact, label: 'Contato' },
]

export const SiteNavigationItems = () => {
  const { asPath } = useRouter()

  if (asPath.includes('app')) return null

  return (
    <Flex
      display={{ base: 'none', lg: '-webkit-flex' }}
      direction={{ base: 'row' }}
      pl={6}
    >
      <IsNotAuthenticated>
        {navigationItems.map(n => (
          <NavigationButton
            label={n.label}
            destination={n.destination}
            key={n.destination}
          />
        ))}
      </IsNotAuthenticated>
    </Flex>
  )
}

interface NavigationButtonProps {
  label: string
  destination: string
}

export const NavigationButton: FC<NavigationButtonProps> = props => {
  const { label, destination } = props
  return (
    <Link passHref href={destination}>
      <Flex
        px={4}
        h='100%'
        fontWeight={600}
        fontSize='md'
        color='heading'
        _hover={{
          color: 'primary',
          cursor: 'pointer',
        }}
      >
        {label}
      </Flex>
    </Link>
  )
}
