import { FormControl, FormErrorMessage, Input, VStack } from '@chakra-ui/react'
import { FormConfirm } from 'form/components/confirm'
import { FieldValidator } from 'form/components/form'
import { QuestionTitle } from 'form/components/title'
import { FormAnswer } from 'form/entities/answer'
import { FormField } from 'form/entities/field'
import { FC, MutableRefObject, useEffect, useRef } from 'react'
import { useForm, useFormState } from 'react-hook-form'
import { maskPhone, numbersOnly } from 'utils/string'
import { validatePhone } from 'utils/validation'

interface FieldProps {
  validator: MutableRefObject<FieldValidator>
  answer: FormAnswer
  field: FormField
  position: number
  isLast: boolean
  onDone: (answer: FormAnswer) => void
}

export const FormPhoneField: FC<FieldProps> = props => {
  const { validator, answer, position, isLast, field, onDone } = props

  const valueRef = useRef<any>(null)

  const {
    register,
    getValues,
    setError,
    control,
    clearErrors,
    setValue,
    handleSubmit,
  } = useForm<FormAnswer & { mask: string }>({
    defaultValues: {
      field: answer?.field || field._id,
      name: field.name || '',
      type: answer?.type || 'phone_number',
      value: maskPhone(answer?.value || ''),
    },
  })
  const { ref } = register('value')
  const { errors } = useFormState({ control })

  const isFieldValid = () => {
    const data = getValues()

    if (!!!data.value && field.required) {
      setError('value', {
        message: 'Por favor, preencha este campo',
      })
      return false
    }

    const sanitizedPhone = numbersOnly(data.value)

    if (!validatePhone(sanitizedPhone)) {
      setError('value', {
        message: 'Por favor, digite um número válido',
      })
      return false
    }

    return true
  }

  const onSubmit = handleSubmit((data: FormAnswer) => {
    if (!isFieldValid()) return

    const sanitizedPhone = numbersOnly(data.value)
    const toSave = `+55${sanitizedPhone}`

    onDone({ ...data, value: toSave })
  })

  const onKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      onSubmit()
    }
  }

  useEffect(() => {
    validator.current = isFieldValid
    document.addEventListener('keydown', onKeyDown)

    if (valueRef.current) {
      valueRef.current.focus()
    }

    return () => document.removeEventListener('keydown', onKeyDown)
  }, [ref])

  return (
    <VStack w={'full'} align={'flex-start'}>
      <QuestionTitle
        title={position + 1 + ' - ' + field.title}
        description={field.description}
      />
      <FormControl isInvalid={!!errors.value?.message}>
        <Input
          type={'tel'}
          autoComplete='tel'
          variant='ghost'
          borderRadius={0}
          pl={0}
          pb={2}
          fontSize='2xl'
          autoFocus
          borderBottom='1px solid'
          placeholder={'41 99999-9999'}
          ref={register('value').ref}
          onKeyUp={e => {
            const value = e.currentTarget.value
            setTimeout(function () {
              let v = maskPhone(value)
              const sanitizedPhone = numbersOnly(v)

              if (!!errors.value?.message && validatePhone(sanitizedPhone)) {
                clearErrors()
              }

              setValue('value', v)

              if (v != value) {
                if (e.key === 'Backspace') {
                  ;(e.target as any).value = v.slice(0, v.length - 1)
                } else {
                  ;(e.target as any).value = v
                }
              }
            }, 1)
          }}
        />
        {errors.value && (
          <FormErrorMessage pt={1} pb={4} fontWeight={600}>
            {errors.value.message}
          </FormErrorMessage>
        )}
      </FormControl>
      {!!!errors.value && (
        <FormConfirm  label={isLast ? 'Enviar' : 'OK'} />
      )}
    </VStack>
  )
}
