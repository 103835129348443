import { Enrollment, EnrollmentFilter } from 'enrollment/entities/enrollment'
import {
  enrollCourseMutation,
  updateEnrollmentMutation,
} from 'enrollment/graphql/mutations'
import { enrollmentQuery, enrollmentsQuery } from 'enrollment/graphql/queries'
import { HttpProvider } from 'infra/http'
import { dt, locator } from 'infra/locator'
import { Model } from 'infra/model'

export class EnrollmentProvider {
  private http: HttpProvider

  constructor() {
    this.http = locator.get<HttpProvider>(dt.Http)
  }

  enrollment = async (key: string) => {
    return this.http
      .graphql<{ enrollment: Enrollment }>(enrollmentQuery, { key })
      .then(r => r.enrollment)
  }

  enrollments = async (filter: Partial<EnrollmentFilter>) => {
    return this.http
      .graphql<{ enrollments: Enrollment[] }>(enrollmentsQuery, { filter })
      .then(r => r.enrollments)
  }

  enroll = async (id: string) => {
    return this.http
      .graphql<{ enrollCourse: Model }>(enrollCourseMutation, { id })
      .then(r => r.enrollCourse)
  }

  update = async (data: Partial<Enrollment>) => {
    return this.http
      .graphql<{ updateEnrollment: Model }>(updateEnrollmentMutation, { data })
      .then(r => r.updateEnrollment)
  }
}
