import { Box } from '@chakra-ui/react'
import { FC, useEffect, useRef, useState } from 'react'

interface Props {
  fields: number
  position: number
  success: boolean
}

export const FormProgress: FC<Props> = ({ success, fields, position }) => {
  const progressRef = useRef<HTMLDivElement | null>(null)

  let [size, setSize] = useState(0)

  const calculateSize = () => {
    let blockSize = 0
    if (progressRef.current) {
      const box = progressRef.current.getBoundingClientRect()
      blockSize = box.width / fields
      if (position > 0) {
        blockSize *= position + 1
      }
    }
    setSize(blockSize)
  }

  useEffect(() => {
    calculateSize()
  }, [fields, position])

  if (success) return null

  return (
    <Box ref={progressRef} bg={'#98baf9'} w='full'>
      <Box h='6px' bg='primary' w={`${size}px`} />
    </Box>
  )
}
