import { AccountProvider } from 'account/providers/account'
import { AuthProvider } from 'auth/providers/auth'
import { BerrymindProvider } from 'berrymind/providers/berrymind'
import { CourseProvider } from 'course/providers/course'
import { CreditProvider } from 'credit/providers/credit'
import { EnrollmentProvider } from 'enrollment/providers/enrollment'
import { FormProvider } from 'form/providers/form'
import { HttpProvider } from 'infra/http'
import { LectureProvider } from 'lecture/providers/lecture'
import { PersonProvider } from 'person/providers/person'
import { ScheduleProvider } from 'schedule/providers/schedule'
import { TestimonialProvider } from 'testimonial/provider/testimonial'
import { UserProvider } from 'user/providers/user'

class Container {
  services: Record<symbol | string, any> = {}

  bind = <T>(key: any, service: T): void => {
    this.services[key] = service
  }

  get = <T>(key: symbol): T => this.services[key as any] as T
}

export const dt = {
  Account: Symbol.for('accountProvider'),
  Auth: Symbol.for('authProvider'),
  Berrymind: Symbol.for('berrymindProvider'),
  Course: Symbol.for('courseProvider'),
  Credit: Symbol.for('creditProvider'),
  Enrollment: Symbol.for('enrollmentProvider'),
  Form: Symbol.for('form'),
  GoogleAds: Symbol.for('googleTracking'),
  Http: Symbol.for('http'),
  Lecture: Symbol.for('lectureProvider'),
  Person: Symbol.for('personProvider'),
  Schedule: Symbol.for('scheduleProvider'),
  Testimonial: Symbol.for('testimonialProvider'),
  User: Symbol.for('userProvider'),
}

export const locator = new Container()
export const registerServices = (): void => {
  locator.bind<HttpProvider>(dt.Http, new HttpProvider())
  locator.bind<AccountProvider>(dt.Account, new AccountProvider())
  locator.bind<AuthProvider>(dt.Auth, new AuthProvider())
  locator.bind<CourseProvider>(dt.Course, new CourseProvider())
  locator.bind<CreditProvider>(dt.Credit, new CreditProvider())
  locator.bind<BerrymindProvider>(dt.Berrymind, new BerrymindProvider())
  locator.bind<EnrollmentProvider>(dt.Enrollment, new EnrollmentProvider())
  locator.bind<FormProvider>(dt.Form, new FormProvider())
  locator.bind<LectureProvider>(dt.Lecture, new LectureProvider())
  locator.bind<ScheduleProvider>(dt.Schedule, new ScheduleProvider())
  locator.bind<TestimonialProvider>(dt.Testimonial, new TestimonialProvider())
  locator.bind<UserProvider>(dt.User, new UserProvider())
  locator.bind<PersonProvider>(dt.Person, new PersonProvider())
}
