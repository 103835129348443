import { Draft } from 'immer'
import { StoreState } from 'stores/root'

export interface FormStoreInterface {
  modalOpen: boolean
  formId: string
  account: string
  source: string
  landingPage?: string
  pipeline?: string
  product?: string
  stage?: string
}

export const FormStore = {
  modalOpen: false,
  formId: '',
  account: '',
  source: '',
  landingPage: undefined,
  pipeline: undefined,
  product: undefined,
  stage: undefined,
}

export const closeFormMutation = (s: Draft<StoreState>) => {
  s.form.modalOpen = false
  s.form.landingPage = undefined
  s.form.pipeline = undefined
  s.form.product = undefined
  s.form.stage = undefined
  s.form.formId = ''
  s.form.account = ''
  s.form.source = ''
}
