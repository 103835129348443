import { Flex, HStack, Text } from '@chakra-ui/react'
import { FC } from 'react'
import { BsCheck } from 'react-icons/bs'

interface Props {
  label: string
  isActive: boolean
}

export const FormChoice: FC<Props> = ({ label, isActive }) => {
  return (
    <HStack justify='space-between'>
      <Text fontSize={'md'}>{label}</Text>
      <Flex flex={1} />
      {isActive ? <BsCheck size={20} /> : undefined}
    </HStack>
  )
}
