import {
  Avatar,
  Box,
  Button,
  Center,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react'
import { LogoutUseCase } from 'auth/cases/logout'
import { IsAuthenticated, IsNotAuthenticated } from 'auth/components/auth'
import { navigationItems } from 'layout/navigation'
import { useRouter } from 'next/router'
import { Routes } from 'routes'
import { getUserSelector } from 'stores/auth'
import { closeDrawerMutation, getDrawerOpen } from 'stores/layout'
import { PullstateCore } from 'stores/root'
import { DrawerItem } from 'user/components/item'
import { addCdn } from 'utils/env'

export const AppDrawer = () => {
  const router = useRouter()

  const { Store } = PullstateCore.useStores()
  const user = Store.useState(getUserSelector)
  const isOpen = Store.useState(getDrawerOpen)

  const onLogout = () => {
    Store.update(s => {
      s.layout.drawer.isOpen = false
    })
    const logoutUseCase = new LogoutUseCase()
    logoutUseCase.execute(router)
  }

  const onRouteClick = (destination: string) => () => {
    Store.update(s => {
      s.layout.drawer.isOpen = false
    })
    router.push(destination)
  }

  const onClose = () => {
    Store.update(closeDrawerMutation)
  }

  return (
    <Drawer placement='right' isOpen={isOpen} onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader p={6}>
          <Image
            mb={4}
            alt={'Berrytech Logo'}
            src={addCdn('/images/logos/logo_berrytech.svg')}
            h={['40px', '40px']}
          />
          <IsAuthenticated>
            <HStack pb={4} w={'full'} spacing={4}>
              <Avatar
                title={user?.name}
                cursor={'pointer'}
                size={'sm'}
                src={user?.avatar}
              />
              <VStack spacing={0} align={'flex-start'}>
                <Text textStyle={'h5'}>{user?.name}</Text>
                <Text textStyle={'caption'}>{user?.email}</Text>
              </VStack>
              {/* <Button
                onClick={onRouteClick(Routes.Profile)}
                variant={'icon'}
                size={'sm'}
              >
                <HiOutlineCog size={24} />
              </Button> */}
            </HStack>
            <Center h={4}>
              <Divider w={'full'} />
            </Center>
          </IsAuthenticated>
        </DrawerHeader>
        <DrawerBody p={0}>
          <VStack w={'full'} h={'full'} flexGrow={1} spacing={6}>
            <IsNotAuthenticated>
              {navigationItems.map(n => (
                <DrawerItem
                  key={n.destination}
                  title={n.label}
                  subtitle={''}
                  onClick={onRouteClick(n.destination)}
                />
              ))}
            </IsNotAuthenticated>
            {/* <DrawerItem
              title={'Consultoria'}
              subtitle={'Projetos, checkpoints e atividades'}
              onClick={onRouteClick(Routes.Berrrymind)}
            /> */}
            {/* <DrawerItem
              title={'Marketing'}
              subtitle={'Acompahe suas campanhas'}
              onClick={onRouteClick(Routes.Marketing)}
            /> */}

            <IsAuthenticated>
              <DrawerItem
                title={'Berryuni'}
                subtitle={'Acompanhe e iniciei seus cursos'}
                onClick={onRouteClick(Routes.Enrollments)}
              />
              <DrawerItem
                title={'Berrymind'}
                subtitle={'Psicologia Empresarial'}
                onClick={onRouteClick(Routes.BerrrymindBookings)}
              />
            </IsAuthenticated>
            {/* <DrawerItem
              title={'Cobranças'}
              subtitle={'Gerencie suas cobranças'}
              onClick={onRouteClick(Routes.Invoices)}
            /> */}
            {/* <VStack spacing={0} align={'flex-start'} px={6}>
              <Text textStyle={'h5'}></Text>
              <Text textStyle={'caption'}></Text>
            </VStack>
            <VStack spacing={0} align={'flex-start'} px={6}>
              <Text textStyle={'h5'}>Projetos</Text>
              <Text textStyle={'caption'}>
                Gerencie seus projetos com a consultoria
              </Text>
            </VStack>
            <VStack spacing={0} align={'flex-start'} px={6}>
              <Text textStyle={'h5'}>Checkpoints</Text>
              <Text textStyle={'caption'}>Acompanhe seus checkpoints</Text>
            </VStack>
            <VStack spacing={0} align={'flex-start'} px={6}>
              <Text textStyle={'h5'}>Cursos</Text>
              <Text textStyle={'caption'}>Gerencie seus cursos</Text>
            </VStack>
            <VStack spacing={0} align={'flex-start'} px={6}>
              <Text textStyle={'h5'}>Cobranças</Text>
              <Text textStyle={'caption'}>
                Acompanhe seu histórico de pagamentos
              </Text>
            </VStack> */}
            <Flex flex={1} />
            <IsNotAuthenticated>
              <Box p={4} w={'full'}>
                <Button
                  w={'full'}
                  variant={'ghost'}
                  onClick={() => {
                    Store.update(s => {
                      s.auth.login = true
                    })
                  }}
                >
                  Acessar Portal
                </Button>
              </Box>
            </IsNotAuthenticated>
            <IsAuthenticated>
              <Flex p={4} w={'full'}>
                <Button
                  w={'full'}
                  onClick={onLogout}
                  variant={'ghost'}
                  size={'sm'}
                >
                  Sair
                </Button>
              </Flex>
            </IsAuthenticated>
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
