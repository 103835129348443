export const modelFrom = (obj: Record<string, any>) => {
  if (!obj || !obj._id) throw new Error('invalid_object')

  return {
    _id: obj._id,
    _key: obj._key,
    _rev: obj._rev,
  }
}

export const mapToIds = <T>(obj: Partial<any>, keys: string[]): T => {
  const updated = Object.assign({}, obj) as T
  for (const key of keys) {
    const value = (updated as any)[key]
    if (value && typeof value !== 'string') {
      ;(updated as any)[key] = value['_id']
    }
  }
  return updated
}