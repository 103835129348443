export const hasPaymentInfoQuery = `
  query personHasPaymentInfo {
    personHasPaymentInfo {
      hasInfo
      person {
        _id
        _key
        _rev
        name
        taxId
        address {
          zip
          street
          number
          complement
          neighborhood
          city
          state
          country
          cityCode
        }
      }
    }
  }
`

export const companyAddressInfoQuery = `
  query companyAddressInfo($zipCode: String!) {
    companyAddressInfo(zipCode: $zipCode) {
      zip
      state
      city
      cityCode
      street
      neighborhood
    }
  }
`
