import { Draft } from 'immer'
import { StoreState } from 'stores/root'

export interface LayoutStore {
  isMobile: boolean
  drawer: {
    isOpen: boolean
  }
}

export const LayoutStore = {
  isMobile: false,
  drawer: {
    isOpen: false,
  },
}

export const getIsMobileSelector = (s: StoreState) => s.layout.isMobile
export const getDrawerOpen = (s: StoreState) => s.layout.drawer.isOpen

export const openDrawerMutation = (s: Draft<StoreState>) => {
  s.layout.drawer.isOpen = true
}

export const closeDrawerMutation = (s: Draft<StoreState>) => {
  s.layout.drawer.isOpen = false
}
