import { HttpProvider } from 'infra/http'
import { dt, locator } from 'infra/locator'
import { Model } from 'infra/model'
import { AvailabilityRequest } from 'schedule/entities/availability'
import { Booking, BookingFilter } from 'schedule/entities/booking'
import {
  CalendarAvailability,
  Schedule,
  ScheduleFilter,
} from 'schedule/entities/schedule'
import {
  cancelBookingMutation,
  createBookingMutation,
  removeScheduleMutation,
  updateBookingMutation,
  updateScheduleMutation,
} from 'schedule/graphql/mutations'
import {
  bookingsQuery,
  scheduleAvailabilityQuery,
  scheduleQuery,
  schedulesQuery,
} from 'schedule/graphql/queries'
import { mapToIds } from 'utils/model'

export class ScheduleProvider {
  private http: HttpProvider

  constructor() {
    this.http = locator.get<HttpProvider>(dt.Http)
  }

  async schedule(key: string) {
    return this.http
      .graphql<{ schedule: Schedule }>(scheduleQuery, { key })
      .then(r => r.schedule)
  }

  async schedules(filter: Partial<ScheduleFilter>) {
    return this.http
      .graphql<{ schedules: Schedule[] }>(schedulesQuery, { filter })
      .then(r => r.schedules)
  }

  async bookings(filter: Partial<BookingFilter>) {
    return this.http
      .graphql<{ bookings: Booking[] }>(bookingsQuery, { filter })
      .then(r => r.bookings)
  }

  async availability(data: AvailabilityRequest) {
    return this.http
      .graphql<{ availability: CalendarAvailability }>(
        scheduleAvailabilityQuery,
        { data },
      )
      .then(r => r.availability)
  }

  async updateBooking(data: Partial<Booking>) {
    const mapped = mapToIds(data, ['account', 'schedule'])
    return this.http
      .graphql<{ updateBooking: Booking }>(updateBookingMutation, {
        data: mapped,
      })
      .then(r => r.updateBooking)
  }

  async cancelBooking(id: string) {
    return this.http
      .graphql<{ cancelBooking: Booking }>(cancelBookingMutation, {
        id,
      })
      .then(r => r.cancelBooking)
  }

  async updateSchedule(data: Partial<Schedule>) {
    const mapped = mapToIds(data, ['product'])
    return this.http
      .graphql<{ updateSchedule: Model }>(updateScheduleMutation, {
        data: mapped,
      })
      .then(r => r.updateSchedule)
  }

  async removeSchedule(key: string) {
    return this.http
      .graphql<{ removeSchedule: Model }>(removeScheduleMutation, { key })
      .then(r => r.removeSchedule)
  }

  async createBooking(data: Partial<Booking>) {
    const mapped = mapToIds(data, ['account', 'invitee', 'schedule'])
    return this.http
      .graphql<{ createBooking: Model }>(createBookingMutation, {
        data: mapped,
      })
      .then(r => r.createBooking)
  }
}
