import { Course, CourseFilter } from 'course/entities/course'
import {
  archiveCourseMutation,
  updateCourseMutation,
} from 'course/graphql/mutations'
import { courseQuery, coursesQuery } from 'course/graphql/queries'
import { HttpProvider } from 'infra/http'
import { dt, locator } from 'infra/locator'
import { Model } from 'infra/model'

export class CourseProvider {
  private http: HttpProvider

  constructor() {
    this.http = locator.get<HttpProvider>(dt.Http)
  }

  async courses(filter: Partial<CourseFilter>) {
    return this.http
      .graphql<{ courses: Course[] }>(coursesQuery, { filter })
      .then(r => r.courses)
  }

  async course(key: string) {
    return this.http
      .graphql<{ course: Course }>(courseQuery, { key })
      .then(r => r.course)
  }

  async update(data: Course) {
    return this.http
      .graphql<{ updateCourse: Model }>(updateCourseMutation, { data })
      .then(r => r.updateCourse)
  }

  async archive() {
    return this.http
      .graphql<{ archiveCourse: Model }>(archiveCourseMutation)
      .then(r => r.archiveCourse)
  }
}
