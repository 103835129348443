import { Button, HStack } from '@chakra-ui/react'
import { FC } from 'react'
import { HiOutlineChevronLeft, HiOutlineChevronRight } from 'react-icons/hi'
import { closeFormMutation } from 'stores/form'
import { PullstateCore } from 'stores/root'

interface Props {
  position: number
  fields: number
  onChangePosition: (position: number) => void
}

export const FormControls: FC<Props> = props => {
  const { position, fields, onChangePosition } = props
  const { Store } = PullstateCore.useStores()

  return (
    <HStack
      position={'fixed'}
      bottom={0}
      left={0}
      right={0}
      justify={'space-between'}
      w={'full'}
      p={4}
      bg={'white'}
      borderTop={'1px solid'}
      borderTopColor={'gray.100'}
    >
      <Button
        onClick={() => Store.update(closeFormMutation)}
        variant={'icon'}
        fontWeight={400}
      >
        Sair
      </Button>
      <HStack>
        <Button
          size='md'
          variant='outline'
          disabled={position === 0}
          onClick={() => onChangePosition(position - 1)}
        >
          <HiOutlineChevronLeft />
        </Button>
        <Button
          size='md'
          variant='outline'
          disabled={position === fields - 1}
          onClick={() => onChangePosition(position + 1)}
        >
          <HiOutlineChevronRight />
        </Button>
      </HStack>
    </HStack>
  )
}
