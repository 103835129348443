export const updateLectureMutation = `
  mutation updateLecture($data: LectureInput!) {
    updateLecture(data: $data) {
      _id
      _key
      _rev
    }
  }
`

export const archiveLectureMutation = `
  mutation archiveLecture($key: String!) {
    archiveLecture(key: $key) {
      _id
      _key
      _rev
    }
  }
`