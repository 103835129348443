import { Account } from 'account/entities/account'
import { Draft } from 'immer'
import { StoreState } from 'stores/root'
import { User } from 'user/entities/user'

export interface AuthStore {
  user?: Partial<User>
  token?: string
  account?: Partial<Account>
  login: boolean
}

export const AuthStore = {
  user: undefined,
  token: undefined,
  account: undefined,
  login: false,
}

export const getUserSelector = (s: StoreState) => s.auth?.user
export const getAuthSelector = (s: StoreState) => s.auth
export const logoutAction = (s: Draft<StoreState>) => {
  s.auth.user = undefined
  s.auth.token = undefined
  s.auth.account = undefined
}
