import confetti from 'canvas-confetti'

export const useConfetti = () => {
  function fire(particleRatio: number, opts: any) {
    confetti(
      Object.assign({}, { origin: { y: 1 }, zIndex: 9999 }, opts, {
        particleCount: Math.floor(350 * particleRatio),
      }),
    )
  }

  const showConfetties = () => {
    fire(0.25, {
      spread: 26,
      startVelocity: 110,
    })
    fire(0.2, {
      spread: 80,
    })
    fire(0.35, {
      spread: 300,
      decay: 0.9,
      scalar: 0.8,
    })
    fire(0.1, {
      spread: 240,
      startVelocity: 70,
      scalar: 1,
    })
    fire(0.1, {
      spread: 240,
      decay: 0.8,
      startVelocity: 120,
    })
  }

  return showConfetties
}
