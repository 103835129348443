import { CircularProgress, Container, Flex, useBoolean } from '@chakra-ui/react'
import { AuthenticateSuccessUseCase } from 'auth/cases/success'
import { AuthProvider } from 'auth/providers/auth'
import { captureError } from 'infra/error'
import { dt, locator } from 'infra/locator'
import { PhoneAuthentication } from 'login/phone_auth'
import { PhoneForm } from 'login/phone_form'
import { FC, useState } from 'react'
import { toast } from 'react-toastify'
import { Store } from 'stores/root'

export const PhoneLoginForm: FC = () => {
  const [phone, setPhone] = useState<string | null>()
  const [validationSent, setValidationSent] = useBoolean()
  const [busy, setBusy] = useBoolean()

  const authProvider = locator.get<AuthProvider>(dt.Auth)

  const sendVerificationCode = async (phone: string) => {
    if (!phone) return
    setBusy.on()
    try {
      await authProvider.sendPhoneValidationCode(phone)
      setValidationSent.on()
    } catch (error) {
      captureError(error)
    } finally {
      setBusy.off()
    }
  }

  const confirmVerificationCode = async (code: string) => {
    if (!phone || !code) {
      return toast.error('Digite um código válido')
    }

    setBusy.on()
    try {
      const response = await authProvider.session({ phone, code })
      if (!!response) {
        const authSuccessUseCase = new AuthenticateSuccessUseCase()
        await authSuccessUseCase.execute({ token: response.token })
        Store.update(s => {
          s.auth.login = false
        })
      }
    } catch (error) {
      captureError(error)
    } finally {
      setBusy.off()
    }
  }

  return (
    <Container maxW='container.sm'>
      {busy && (
        <Flex w='full' justify='center' py={8}>
          <CircularProgress isIndeterminate />
        </Flex>
      )}
      {!busy && !phone && (
        <PhoneForm
          onConfirm={phone => {
            setPhone(phone)
            sendVerificationCode(phone)
          }}
        />
      )}
      {!busy && validationSent && phone && (
        <PhoneAuthentication
          phone={phone}
          onComplete={code => confirmVerificationCode(code)}
        />
      )}
    </Container>
  )
}
