import { HttpProvider } from 'infra/http'
import { dt, locator } from 'infra/locator'
import { Model } from 'infra/model'
import { Person, ZipCodeResponse } from 'person/entities/person'
import { updatePersonMutation } from 'person/graphql/mutations'
import {
  companyAddressInfoQuery,
  hasPaymentInfoQuery,
} from 'person/graphql/queries'

export class PersonProvider {
  private http: HttpProvider

  constructor() {
    this.http = locator.get<HttpProvider>(dt.Http)
  }

  hasPaymentInfo = async () => {
    return this.http
      .graphql<{ personHasPaymentInfo: PersonHasInfoResponse }>(
        hasPaymentInfoQuery,
      )
      .then(r => r.personHasPaymentInfo)
  }

  update = async (data: Partial<Person>) => {
    return this.http
      .graphql<{ updatePerson: Model }>(updatePersonMutation, { data })
      .then(r => r.updatePerson)
  }

  addressInfo = async (zipCode: string) => {
    const info = await this.http
      .graphql<{ companyAddressInfo: ZipCodeResponse }>(
        companyAddressInfoQuery,
        { zipCode },
      )
      .then(r => r.companyAddressInfo)

    return {
      city: info.city,
      country: 'Brasil',
      neighborhood: info.neighborhood,
      state: info.state,
      street: info.street,
      zip: info.zip,
      cityCode: `${info.cityCode}`,
      coordinates: {
        lat: 0,
        lng: 0,
      },
    }
  }
}

interface PersonHasInfoResponse {
  hasInfo: boolean
  person: Person
}
