import {
  BerrymindSettings,
  BerrymindUserInfo,
} from 'berrymind/entities/berrymind'
import {
  updateBerrymindSettingsMutation,
  updateBerrymindUserInfoMutation,
} from 'berrymind/graphql/mutations'
import {
  berrymindPsychologistsQuery,
  berrymindSettingsQuery,
} from 'berrymind/graphql/queries'
import { HttpProvider } from 'infra/http'
import { dt, locator } from 'infra/locator'
import { User } from 'user/entities/user'
import { mapToIds } from 'utils/model'

export class BerrymindProvider {
  private http: HttpProvider

  constructor() {
    this.http = locator.get<HttpProvider>(dt.Http)
  }

  settings = async () => {
    return this.http
      .graphql<{ berrymindSettings: BerrymindSettings }>(berrymindSettingsQuery)
      .then(r => r.berrymindSettings)
  }

  psychologists = async () => {
    return this.http
      .graphql<{ berrymindPsychologists: User[] }>(berrymindPsychologistsQuery)
      .then(r => r.berrymindPsychologists)
  }

  updateUserInfo = async (data: Partial<BerrymindUserInfo>) => {
    return this.http
      .graphql<{ updateBerrymindUserInfo: boolean }>(
        updateBerrymindUserInfoMutation,
        { data },
      )
      .then(r => r.updateBerrymindUserInfo)
  }

  updateSettings = async (data: Partial<BerrymindSettings>) => {
    const mapped = mapToIds(data, ['psychologist'])
    return this.http
      .graphql<{ updateBerrymindSettings: User[] }>(
        updateBerrymindSettingsMutation,
        { data: mapped },
      )
      .then(r => r.updateBerrymindSettings)
  }
}
