import { Box, Button, SlideFade, Text, VStack } from '@chakra-ui/react'
import { useConfetti } from 'common/confetti'
import { motion } from 'framer-motion'
import { FC, useEffect } from 'react'
import { closeFormMutation } from 'stores/form'
import { PullstateCore } from 'stores/root'

interface Props {
  title: string
  subtitle: string
}

export const FormSuccess: FC<Props> = props => {
  const { title, subtitle } = props

  const { Store } = PullstateCore.useStores()
  const confetti = useConfetti()

  const onClose = () => {
    Store.update(closeFormMutation)
  }

  useEffect(() => {
    confetti()
  }, [])

  return (
    <VStack
      w='full'
      h={'calc(100vh - 120px)'}
      justify={'center'}
      textAlign='center'
      maxW={'md'}
      px={6}
    >
      <motion.div
        style={{ width: '100%' }}
        initial={{ opacity: 0, offset: 90 }}
        animate={{ opacity: 1, offset: -90 }}
        exit={{ opacity: 0 }}
      >
        <SlideFade in={true} style={{ width: '100%' }}>
          <Text color={'success'} textStyle='subtitle'>
            Obrigado!
          </Text>
          <Box h={8} />
          <VStack spacing={8}>
            <Text>{title}</Text>
            <Text textStyle={'caption'} fontSize={'md'}>
              {subtitle}
            </Text>
          </VStack>
          <Box h={8} />
          <Button onClick={onClose} variant={'icon'} fontWeight={500}>
            Sair
          </Button>
        </SlideFade>
      </motion.div>
    </VStack>
  )
}
