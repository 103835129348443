export const firstLettersToUppercase = (textArray: string[]) => {
  if (!textArray) return

  const capitalizedArray: string[] = []
  for (const text of textArray) {
    const capitalized = text.charAt(0).toUpperCase()
    let joined = text.replace(text.charAt(0), capitalized)
    capitalizedArray.push(joined)
  }
  return capitalizedArray.join(' ')
}

export const numbersOnly = (string?: string) => {
  if (!!!string) return ''
  return string.replace(/\D/g, '')
}

export function maskPhone(phone: string) {
  if (!!!phone) return ''

  var r = phone.replace('+55', '').replace(/\D/g, '')
  r = r.replace(/^0/, '')

  if (r.length === 0) return ''

  if (r.length > 10) {
    r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3')
  } else if (r.length > 5) {
    r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, '($1) $2-$3')
  } else if (r.length > 2) {
    r = r.replace(/^(\d\d)(\d{0,5})/, '($1) $2')
  } else {
    r = r.replace(/^(\d*)/, '($1')
  }
  return r
}

export const copyTextToClipboard = async (text: string) => {
  if (!navigator.clipboard) {
    return
  }
  return navigator.clipboard.writeText(text)
}
