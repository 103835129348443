import { Account } from 'account/entities/account'
import { HttpProvider } from 'infra/http'
import { dt, locator } from 'infra/locator'
import { User } from 'user/entities/user'
import { meQuery } from 'user/graphql/queries'

export interface UserAccountResponse {
  user: User
  account: Account
}

export class UserProvider {
  private http: HttpProvider

  constructor() {
    this.http = locator.get<HttpProvider>(dt.Http)
  }

  me = async () => {
    return this.http
      .graphql<{ me: UserAccountResponse }>(meQuery)
      .then(r => r.me)
  }
}
