import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react'
import { PhoneLoginForm } from 'login/phone'
import { PullstateCore } from 'stores/root'

export const LoginModal = () => {
  const { Store } = PullstateCore.useStores()
  const isOpen = Store.useState(s => s.auth.login)

  const onClose = () => {
    Store.update(s => {
      s.auth.login = false
    })
  }

  return (
    <Modal size={'sm'} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={6}>
          <PhoneLoginForm />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
