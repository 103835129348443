import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react'
import { BerryForm } from 'form/components/form'
import { closeFormMutation } from 'stores/form'
import { Store } from 'stores/root'

export const FormModal = () => {
  const { modalOpen, formId } = Store.useState(s => s.form)

  return (
    <Modal
      size={'full'}
      isOpen={modalOpen}
      onClose={() => Store.update(closeFormMutation)}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={0}>
          <BerryForm formId={formId} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
