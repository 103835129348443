export const usersQuery = `
  query users($filter: UserFilterInput!) {
     users(filter: $filter) {
       _id
       _key
       _rev
       name
       account {
         _id
         name
       }
       secondName
       avatar
       phone
       email
       avatar
       department
       job
      }
    }
  `

export const userQuery = `
    query user($key: String!) {
        user(key: $key) {
            _id
            _key
            active
            account {
              _id
              name
            }
            name
            secondName
            phone
            email
            roles
            permissions
            avatar
            googleToken
            googleRefreshToken
            age
            department
            job
            location
            passions
            expertise
            instagram
            linkedin
        }
    }
`

export const meQuery = `
  query me {
    me {
      user {
        _id
        _key
        _rev
        account {
          _id
          _key
          active
          type
          name
        }
        person {
          _id
          _key
          name
        }
        active
        phone
        name
        email
        avatar
        roles
        permissions
        department
        job
        googleToken
        googleRefreshToken
        location
        age
        passions
        expertise
        instagram
        linkedin
        pushToken
        createdAt
        updatedAt
      }
      
    }
  }
`

export const consultantsBonuses = `
  query consultantsBonuses ($filter: BonusFilter!) {
    consultantsBonuses (filter: $filter) {
      responsible {
        _id
        _key
        _rev
        name
        secondName
        avatar
        account {
          _id
          _key
          _rev
          name
        }
      }
      projects
      meets
      revenue
      canceled
      lostRevenue
      churn
      churnGoal
      bonus
      grossBonus
    }
  }
`

export const consultantBonusQuery = `
query consultantBonus {
  consultantBonus {
      responsible {
        _id
        _key
        _rev
        name
        secondName
        avatar
      }
      projects
      meets
      revenue
      canceled
      lostRevenue
      churn
      churnGoal
      bonus
      grossBonus
  }
}
`

export const consultantMeetOverviews = `
  query consultantMeetOverviews ($filter: ConsultantMeetRatingsFilter!) {
    consultantMeetOverviews (filter: $filter) {
      responsible {
        _id
        _key
        _rev
        name
        secondName
        avatar
        account {
          _id
          _key
          _rev
          name
        }
      }
      meets
      ratedMeets
      meanRating
      meanClientRating
    }
  }
`
