export const respondToFormMutation = `
  mutation respondToForm($data: FormResponseInput!) {
    respondToForm(data: $data)
  }
`
export const updateFormResponseMutation = `
  mutation updateFormResponse($data: FormResponseInput!) {
    updateFormResponse(data: $data) {
      _id
      _key
      _rev
    }
  }
`

export const addToMailingListMutation = `
  mutation addToMailingList($data: SendGridContactRequestInput!) {
    addToMailingList(data: $data)
  }
`
