import {
  Center,
  CircularProgress,
  Flex,
  SlideFade,
  useBoolean,
  VStack,
} from '@chakra-ui/react'
import { ConvertFormUseCase } from 'form/case/convert_form'
import { FormControls } from 'form/components/controls'
import { FormProgress } from 'form/components/progress'
import { FormSuccess } from 'form/components/success'
import { FormAnswer, FormResponse } from 'form/entities/answer'
import { FormField } from 'form/entities/field'
import { FormChoiceField } from 'form/fields/choice'
import { FormDateField } from 'form/fields/date'
import { FormEmailField } from 'form/fields/email'
import { FormPhoneField } from 'form/fields/phone'
import { FormTextField } from 'form/fields/short_text'
import { FormYesNoField } from 'form/fields/yes_no'
import { FormProvider } from 'form/providers/form'
import { motion } from 'framer-motion'
import { captureError } from 'infra/error'
import { dt, locator } from 'infra/locator'
import { fetchMarketingParams } from 'infra/marketing'
import { FC, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { getFranchiseAccount } from 'stores/franchise'
import { PullstateCore } from 'stores/root'
import useSWR from 'swr'
import { modelFrom } from 'utils/model'

interface Answers {
  [key: string]: FormAnswer
}

interface Props {
  formId: string
}

export type FieldValidator = () => boolean

export const BerryForm: FC<Props> = props => {
  const { formId } = props

  const { Store } = PullstateCore.useStores()
  const franchise = Store.useState(getFranchiseAccount)

  const [busy, setBusy] = useBoolean()
  const [isSuccess, setIsSuccess] = useBoolean(false)
  const [position, setPosition] = useState<number>(0)
  const [answers, setAnswers] = useState<Answers>({})
  const [response, setResponse] = useState<FormResponse | null>(null)
  const validatorRef = useRef<FieldValidator>(() => false)

  const formProvider = locator.get<FormProvider>(dt.Form)

  const { data: form, isValidating } = useSWR(
    `form-${formId}`,
    () => formProvider.form(formId),
    { revalidateOnFocus: false },
  )

  const fields = form?.fields || []
  const field = fields[position]

  const onFormStart = async () => {
    if (response) return

    const request: Partial<FormResponse> = {
      form: formId,
      answers: Object.values(answers) || [],
      ...fetchMarketingParams(),
    }

    try {
      const savedResponse = await formProvider.updateResponse(request)
      if (savedResponse && savedResponse._key) {
        setResponse(savedResponse as any)
      }
    } catch (error) {
      captureError(error, {
        name: 'Erro ao criar resposta no formulário',
        formId,
      })
    }
  }

  const onFinish = async () => {
    if (!!!answers || !form || !response) return

    setBusy.on()

    const savedResponse: Partial<FormResponse> = {
      ...modelFrom(response),
      form: formId,
      answers: Object.values(answers),
      ...fetchMarketingParams(),
    }

    const convertFormUseCase = new ConvertFormUseCase()
    convertFormUseCase.execute({
      form,
      response: savedResponse,
      franchise,
    })

    try {
      await formProvider.respondToForm(savedResponse)
      setIsSuccess.on()
    } catch (error) {
      captureError(error)
      toast.error('Erro ao enviar o formulário. Tente novamente.')
    } finally {
      setBusy.off()
    }
  }

  const onAnswer = async (field: FormField, answer: FormAnswer) => {
    const id = field._id || ''
    const answersCopy = Object.assign(answers, { [id]: answer })
    setAnswers(answersCopy)

    if (response) {
      try {
        await formProvider.updateResponse({
          ...modelFrom(response),
          answers: Object.values(answersCopy),
        })
      } catch (error) {
        captureError(error, {
          name: 'Erro ao salvar resposta no formulário',
          formId,
        })
      }
    } else {
      onFormStart()
    }
    const nextPosition = position + 1
    if (nextPosition === fields.length) {
      onFinish()
    } else {
      setPosition(position + 1)
    }
  }

  const onChangePosition = (nextPos: number) => {
    if (nextPos > position) {
      if (validatorRef.current()) {
        setPosition(nextPos)
      }
    }
  }

  if (!form) return null

  if (isValidating || busy) {
    return (
      <VStack w={'100vw'} h={'100vh'} align={'center'} justify={'center'}>
        <Center>
          <CircularProgress
            size={16}
            color={'accent'}
            value={75}
            isIndeterminate
          />
        </Center>
      </VStack>
    )
  }

  return (
    <VStack h={'full'}>
      <FormProgress
        success={isSuccess}
        fields={fields.length}
        position={position}
      />
      {isSuccess ? (
        <FormSuccess
          title={form.success.title}
          subtitle={form.success.subtitle}
        />
      ) : (
        <VStack h={'90vh'} w={'full'} maxW={'xl'} overflowY='scroll'>
          <Flex flex={1} />
          {field && (
            <motion.div
              style={{ width: '100%' }}
              key={position}
              initial={{ opacity: 0, offset: 90 }}
              animate={{ opacity: 1, offset: -90 }}
              exit={{ opacity: 0 }}
            >
              <SlideFade in={!!field} offsetY={200} style={{ width: '100%' }}>
                <VStack
                  id={'field-content'}
                  spacing={4}
                  align='flex-start'
                  w={'full'}
                  overflowY='scroll'
                  px={4}
                  pb={8}
                >
                  {field.type === 'short_text' && (
                    <FormTextField
                      validator={validatorRef}
                      answer={answers[field._id as string]}
                      field={field}
                      isLast={position === fields.length - 1}
                      position={position}
                      onDone={answer => onAnswer(field, answer)}
                    />
                  )}
                  {field.type === 'email' && (
                    <FormEmailField
                      validator={validatorRef}
                      answer={answers[field._id as string]}
                      field={field}
                      isLast={position === fields.length - 1}
                      position={position}
                      onDone={answer => onAnswer(field, answer)}
                    />
                  )}
                  {field.type === 'phone_number' && (
                    <FormPhoneField
                      validator={validatorRef}
                      answer={answers[field._id as string]}
                      field={field}
                      isLast={position === fields.length - 1}
                      position={position}
                      onDone={answer => onAnswer(field, answer)}
                    />
                  )}
                  {field.type === 'multiple_choice' && (
                    <FormChoiceField
                      validator={validatorRef}
                      answer={answers[field._id as string]}
                      field={field}
                      isLast={position === fields.length - 1}
                      position={position}
                      onDone={answer => onAnswer(field, answer)}
                    />
                  )}
                  {field.type === 'yes_no' && (
                    <FormYesNoField
                      validator={validatorRef}
                      answer={answers[field._id as string]}
                      field={field}
                      isLast={position === fields.length - 1}
                      position={position}
                      onDone={answer => onAnswer(field, answer)}
                    />
                  )}
                  {field.type === 'date' && (
                    <FormDateField
                      validator={validatorRef}
                      answer={answers[field._id as string]}
                      field={field}
                      isLast={position === fields.length - 1}
                      position={position}
                      onDone={answer => onAnswer(field, answer)}
                    />
                  )}
                </VStack>
              </SlideFade>
            </motion.div>
          )}
          <Flex flex={1} />
          <FormControls
            fields={fields.length}
            onChangePosition={onChangePosition}
            position={position}
          />
        </VStack>
      )}
    </VStack>
  )
}
