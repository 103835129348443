import { Account } from 'account/entities/account'
import { captureMessage } from 'infra/error'
import { dt, locator } from 'infra/locator'
import { fetchMarketingParams } from 'infra/marketing'
import Script from 'next/script'
import { FC } from 'react'
import { isDev } from 'utils/env'

let global: any = {}

if (typeof window !== 'undefined') {
  global = window
}

export class GoogleAds {
  trackingId: string = ''

  constructor(trackingId: string) {
    this.trackingId = trackingId
  }

  trackTiming = (params: TrackEventParams) => {
    if (global.gtag) {
      const mParams = fetchMarketingParams()
      global.gtag('event', 'timing', {
        send_to: this.trackingId,
        name: params.name,
        ...mParams,
        ...params.params,
      })
    }
  }

  trackPageview = () => {
    if (global.gtag) {
      const mParams = fetchMarketingParams()
      global.gtag('event', 'page_view', {
        send_to: this.trackingId,
        ...mParams,
      })
    }
  }

  conversion = (conversionId: string) => {
    if (global.gtag) {
      const mParams = fetchMarketingParams()
      captureMessage(`Captured conversion ${conversionId}`, mParams)
      global.gtag('event', 'conversion', {
        send_to: conversionId,
        ...mParams,
      })
    }
  }

  trackEvent = (params: TrackEventParams) => {
    if (global.gtag) {
      const mParams = fetchMarketingParams()
      global.gtag('event', params.name, {
        send_to: this.trackingId,
        ...mParams,
        ...params.params,
      })
    }
  }
}

interface EnhancedConversionData {
  email: string
  phone: string
  name: string
}

export const storeEnhancedConversionData = (data: EnhancedConversionData) => {
  global.enhanced_conversion_data = global.enhanced_conversion_data || {}

  let email, phone, firstName, lastName

  email = data.email?.trim().toLowerCase()
  phone = data.phone?.trim().toLowerCase()

  if (data.name) {
    firstName = data.name.split(' ').shift()?.trim().toLowerCase()
    lastName = data.name.split(' ').pop()?.trim().toLowerCase()
  }

  global.enhanced_conversion_data['email'] = email
  global.enhanced_conversion_data['phone_number'] = phone
  global.enhanced_conversion_data['first_name'] = firstName
  global.enhanced_conversion_data['last_name'] = lastName
  global.enhanced_conversion_data['address'] = { country: 'BR' }
}

interface GoogleAnalyticsProps {
  franchise: Account
}

interface TrackEventParams {
  name: string
  params?: Record<string, any>
}

export const GoogleAnalytics: FC<GoogleAnalyticsProps> = props => {
  const { franchise } = props

  const trackingId = franchise?.google?.trackingId
  const gads = new GoogleAds(trackingId)
  locator.bind<GoogleAds>(dt.GoogleAds, gads)

  return (
    <>
      <Script
        strategy='afterInteractive'
        src={`https://www.googletagmanager.com/gtag/js?id=${trackingId}`}
      />
      <Script
        id='google-script'
        strategy='afterInteractive'
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${trackingId}', {
              send_page_view: false,
              allow_enhanced_conversion: true,
              debug_mode: ${isDev()},
            });
          `,
        }}
      />
    </>
  )
}
