import {
  redeemCreditsMutation,
  transferCreditsMutation,
} from 'berrymind/graphql/mutations'
import {
  AddCreditsRequest,
  CreditTransfer,
  RedeemTransferRequest,
  TransferCreditsRequest,
} from 'credit/entities/credit'
import { CreditOrder } from 'credit/entities/order'
import { addBerrymindCreditsMutation } from 'credit/graphql/mutations'
import { creditsOrdersQuery } from 'credit/graphql/queries'
import { HttpProvider } from 'infra/http'
import { dt, locator } from 'infra/locator'

export class CreditProvider {
  private http: HttpProvider

  constructor() {
    this.http = locator.get<HttpProvider>(dt.Http)
  }

  credits = async () => {
    return this.http
      .graphql<{ credits: CreditOrder[] }>(creditsOrdersQuery)
      .then(r => r.credits)
  }

  add = async (data: AddCreditsRequest) => {
    return this.http
      .graphql<{ addBerrymindCredits: string }>(addBerrymindCreditsMutation, {
        data,
      })
      .then(r => r.addBerrymindCredits)
  }

  transfer = async (data: TransferCreditsRequest) => {
    return this.http
      .graphql<{ transferCredits: CreditTransfer }>(transferCreditsMutation, {
        data,
      })
      .then(r => r.transferCredits)
  }

  redeem = async (data: RedeemTransferRequest) => {
    return this.http
      .graphql<{ redeemCredits: CreditTransfer }>(redeemCreditsMutation, {
        data,
      })
      .then(r => r.redeemCredits)
  }
}
